import React, { useCallback } from 'react';
import './demos.css';
import ErrorMessage from './ErrorMessage';
import DemoTypeLabel from './DemoTypeLabel';
import IdpTypeLabel from './IdpTypeLabel';
import { useNavigate } from 'react-router-dom';
import { useDemoContext } from '../DemoContext';
import { DataView } from '@okta/odyssey-react-mui/labs';
import {
  Box,
  Button,
  Link,
  Surface,
  Typography,
} from '@okta/odyssey-react-mui';
import CollaboratorList from './collaboration/CollaboratorList';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
const Demos = () => {
  const { demos, idps, error } = useDemoContext();
  const demoContext = useDemoContext();
  const navigate = useNavigate();

  function handleOnClick(path) {
    navigate(path);
  }

  const getData = useCallback(
    ({ search, filters }) => {
      let filteredData = structuredClone(demos);
      if (search && search.length > 0) {
        const lowerCaseSearch = search.toLowerCase();

        filteredData = filteredData.filter((row) =>
          Object.values(row).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(lowerCaseSearch);
            }
            return false;
          })
        );
      }

      function filterData(mainArray, secondArray, filters) {
        let idpTypeValues = [];
        filters.forEach((filter) => {
          if (filter.id === 'idpType' && filter.value?.length) {
            idpTypeValues = filter.value.map((v) => v.value);
          }
        });

        const filteredIdpIds = secondArray
          .filter(
            (item) =>
              idpTypeValues.length === 0 || idpTypeValues.includes(item.type)
          )
          .map((item) => item.idp_id);

        return mainArray.filter((item) => {
          const matchesIdpType =
            idpTypeValues.length === 0 || filteredIdpIds.includes(item.idp_id);
          const matchesOtherFilters = filters.every((filter) => {
            if (filter.id !== 'idpType' && filter.value?.length) {
              const selectedValues = filter.value.map((v) => v.value);
              return selectedValues.includes(item[filter.id]);
            }
            return true;
          });

          return matchesIdpType && matchesOtherFilters;
        });
      }
      if (filters && filteredData.length) {
        return filterData(filteredData, idps, filters);
      }
      return filteredData;
    },
    [demos, idps]
  );

  return (
    <Surface>
      <Box sx={{ marginBottom: ['2rem'] }}>
        <Typography variant="h1">Demo Environments</Typography>
        <Typography variant="subordinate">
          A demonstration environment allows you to contain all of the
          components a demo together and manage them as one.
        </Typography>
      </Box>
      {error && <ErrorMessage error={error} />}
      {demoContext.loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div className="skeletonContainer">
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={45} height={40} />
            </Stack>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={400} height={40} />
            </Stack>
          </div>
          <div className="skeletonContainer">
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={330} height={250} />
            </Stack>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={330} height={250} />
            </Stack>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={330} height={250} />
            </Stack>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={330} height={250} />
            </Stack>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={330} height={250} />
            </Stack>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={330} height={250} />
            </Stack>
          </div>
        </div>
      ) : (
        <DataView
          emptyPlaceholder={
            <Box>
              <Typography variant="h3">
                No demonstration environments found.
              </Typography>
              <Typography variant="support">
                Create a new environment or start a hands-on lab to find the
                environment here.
              </Typography>
            </Box>
          }
          hasSearch
          hasFilters
          filters={[
            {
              id: 'type',
              label: 'Demo Type',
              variant: 'multi-select',
              options: [
                {
                  label: 'Enablement',
                  value: 'enablement',
                },
                {
                  label: 'Opportunity',
                  value: 'opportunity',
                },
                {
                  label: 'Lab',
                  value: 'lab',
                },
              ],
            },
            {
              id: 'idpType',
              label: 'IDP Type',
              variant: 'multi-select',
              options: [
                {
                  label: 'Okta Customer Identity Solution',
                  value: 'okta-customer-identity',
                },
                {
                  label: 'Workforce Identity Cloud',
                  value: 'workforce-identity',
                },
                {
                  label: 'Customer Identity Cloud',
                  value: 'customer-identity',
                },
              ],
            },
          ]}
          availableLayouts={['grid']}
          getData={getData}
          tableLayoutOptions={{
            columns: [
              { accessorKey: 'label', header: 'Name' },
              { accessorKey: 'type', header: 'Environment Type' },
              {
                id: 'idp_id',
                accessorFn: (row) =>
                  idps.find((obj) => {
                    return obj.idp_id === row.idp_id;
                  }).type,
                header: 'Product',
              },
              {
                accessorKey: 'linked_opportunities.length',
                header: 'Attached Opps',
              },
              { accessorKey: 'collaborators.length', header: 'Shared' },
            ],
            hasChangeableDensity: true,
            hasColumnResizing: true,
            hasColumnVisibility: true,
            hasSorting: true,
            initialDensity: 'comfortable',
            rowActionButton: (row) => (
              <>
                <Button label="View Details" />
              </>
            ),
          }}
          cardLayoutOptions={{
            itemProps: (row) => ({
              overline: <DemoTypeLabel type={row.type} />,
              title: row.label,
              description: row.label === row.name ? row.name : row.name,
              variant: 'compact',
              children: (
                <Box>
                  <IdpTypeLabel idps={idps} idpid={row.idp_id} />
                  <CollaboratorList entity={row} includeManage={false} />
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Link
                      variant="monochrome"
                      onClick={() => handleOnClick('/demo/' + row.name)}
                    >
                      View Details
                    </Link>
                  </Box>
                </Box>
              ),
            }),
          }}
        />
      )}
    </Surface>
  );
};
export default Demos;
