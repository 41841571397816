import React from 'react';
import PropTypes from 'prop-types';
import './CardGroup.css';

const CardGroup = ({ children, className, itemsPerRow, stackable, style }) => {
  const groupClass = `
    custom-card-group
    ${stackable ? 'custom-card-group--stackable' : ''}
    ${itemsPerRow ? `custom-card-group--${itemsPerRow}-per-row` : ''}
    ${className || ''}
  `.trim();

  return (
    <div className={groupClass} style={style}>
      {children}
    </div>
  );
};

CardGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  itemsPerRow: PropTypes.oneOf([2, 3, 4, 5]),
  stackable: PropTypes.bool,
  style: PropTypes.object,
};

CardGroup.defaultProps = {
  className: '',
  itemsPerRow: 4,
  stackable: false,
  style: {},
};

export default CardGroup;
