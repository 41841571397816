import React, { useState } from 'react';
import { BooleanSettingOptions } from '../components/ComponentSettingsConstants';
import AWSRegionSetting from './settings/AWSRegionSetting';
import { Button, Select, TextField } from '@okta/odyssey-react-mui';
import { RefreshIcon } from '@okta/odyssey-react-mui/icons';
import Container from './ui/Container/Container';
import { sortObjectByKeys } from '../util/SortObjectByKeys';
import './InstanceSettings.css';
const InstanceSettings = ({
  defaultSettings,
  instanceSettings,
  editMode,
  onUpdate,
}) => {
  const [settings, setSettings] = useState(defaultSettings);

  function handleChange(event) {
    const { name, value } = event.target;
    var settingKey = name.replace('settings.', '');
    var updateSettings = settings;
    if (value.length > 0 || typeof value === 'boolean') {
      updateSettings[settingKey] = value;
    } else {
      delete updateSettings[settingKey];
    }
    setSettings({ ...updateSettings });
    onUpdate(updateSettings);
  }

  function renderSettingRow(key, index) {
    if (key.startsWith('_')) {
      return null;
    } else {
      return (
        <div
          className={
            index % 2 === 0
              ? 'instanceSettingsRow alternate'
              : 'instanceSettingsRow'
          }
        >
          <div className="cell">{key}</div>
          <div className="cell">{renderSettingField(key)}</div>
          {key !== 'awsAccountId' && (
            <div className="cell">
              {editMode && settings[key] && (
                <Button
                  className="rowAction"
                  label="Use Default"
                  startIcon={<RefreshIcon />}
                  labelPosition="left"
                  variant="secondary"
                  onClick={() => {
                    handleChange(null, {
                      name: 'settings.' + key,
                      value: instanceSettings[key].default,
                    });
                  }}
                />
              )}
            </div>
          )}
        </div>
      );
    }
  }

  function renderSettingField(key) {
    //handle one off fields
    if (key === 'awsRegion') {
      return (
        <AWSRegionSetting
          name="awsRegion"
          instanceValue={settings[key]}
          defaultValue="us-east-1"
          readOnly={!editMode}
          onChange={handleChange}
        />
      );
    }
    if (key === 'awsAccountId' || key === 'InitialInboundIpAddress') {
      return (
        <TextField
          inline
          id={'settings.' + key}
          key={'settings.' + key}
          name={'settings.' + key}
          value={settings[key] ? settings[key] : ''}
          maxLength="1024"
          isReadOnly={true}
          isDisabled
          fluid
          autofocus
        />
      );
    }
    //render by field type
    switch (instanceSettings[key].type) {
      case 'bool': {
        return (
          <Select
            inline
            id={'settings.' + key}
            key={'settings.' + key}
            name={'settings.' + key}
            value={settings[key]}
            onChange={handleChange}
            options={BooleanSettingOptions}
            isDisabled={!editMode}
            placeholder={
              '(default) ' + instanceSettings[key].default.toString()
            }
            selection
            fluid
          />
        );
      }
      case 'secret': {
        return (
          <TextField
            inline
            id={'settings.' + key}
            key={'settings.' + key}
            name={'settings.' + key}
            value={settings[key] ? settings[key] : ''}
            onChange={handleChange}
            maxLength="1024"
            isReadOnly={!editMode}
            isDisabled={!editMode}
            placeholder={'(default) *****'}
            fluid
            type="password"
          />
        );
      }
      default: {
        return (
          <TextField
            inline
            id={'settings.' + key}
            key={'settings.' + key}
            name={'settings.' + key}
            value={settings[key] ? settings[key] : ''}
            onChange={handleChange}
            maxLength="1024"
            isReadOnly={!editMode}
            isDisabled={!editMode}
            placeholder={'(default) ' + instanceSettings[key].default}
            fluid
            autofocus
          />
        );
      }
    }
  }

  return (
    <Container>
      {instanceSettings ? (
        <Container>
          <div id="#custom-table">
            {Object.keys(sortObjectByKeys(instanceSettings)).map((key, index) =>
              renderSettingRow(key, index)
            )}
          </div>
        </Container>
      ) : null}
    </Container>
  );
};

export default InstanceSettings;
