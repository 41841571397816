import InstanceLabel from './InstanceLabel';
import {
  Button,
  MenuButton,
  MenuItem,
  Toast,
  ToastStack,
  Dialog,
  TextField,
  Select,
  CircularProgress,
  Form,
} from '@okta/odyssey-react-mui';
import InstanceSettings from './InstanceSettings';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import Config from '../../Config';
import axios from 'axios';
import ErrorMessage from '../ErrorMessage';
import { sortObjectByKeys } from '../../util/SortObjectByKeys';
import './InstanceConfiguration.css';
import { StorytimeTemplates } from '../../util/StorytimeTemplates';
import { hasUserFacingSetting } from '../../util/Settings';
import Container from '../ui/Container/Container';

const InstanceConfiguration = ({ instance, type, isWaiting, onUpdate }) => {
  let params = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { applicationId } = instance;

  const [componentInstance, setComponentInstance] = useState(instance);
  const [waiting, setWaiting] = useState(isWaiting);
  const [error, setError] = useState(null);
  const [isImportError, setImportError] = useState(false);

  const [isChanged, setIsChanged] = useState(false);
  const [label, setLabel] = useState(instance.label);
  const [settings, setSettings] = useState();
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [isPresetModalOpen, setPresetModalOpen] = useState(false);
  const [importedSettingsValue, setImportedSettingsValue] = useState(null);
  const [changedDefaultKeys, setChangedDefaultKeys] = useState([]);
  const [toast, setToast] = useState(false);

  function handleLabelChange(value) {
    setLabel(value);
  }

  useEffect(() => {
    setSettings(JSON.parse(JSON.stringify(instance.settings)));
  }, [instance.settings]);

  useEffect(() => {
    var outcome = false;
    if (label !== componentInstance.label) {
      outcome = true;
    }
    if (!outcome && componentInstance.settings && settings) {
      if (
        JSON.stringify(sortObjectByKeys(componentInstance.settings)) !==
        JSON.stringify(sortObjectByKeys(settings))
      ) {
        outcome = true;
      }
    }
    setIsChanged(outcome);
  }, [settings, label, componentInstance.label, componentInstance.settings]);
  useEffect(() => {
    setSettings(JSON.parse(JSON.stringify(instance.settings)));
  }, [instance.settings]);

  useEffect(() => {
    var outcome = false;
    if (label !== componentInstance.label) {
      outcome = true;
    }
    if (!outcome && componentInstance.settings && settings) {
      if (
        JSON.stringify(sortObjectByKeys(componentInstance.settings)) !==
        JSON.stringify(sortObjectByKeys(settings))
      ) {
        outcome = true;
      }
    }
    setIsChanged(outcome);
  }, [settings, label, componentInstance.label, componentInstance.settings]);
  useEffect(() => {
    setSettings(JSON.parse(JSON.stringify(instance.settings)));
  }, [instance.settings]);

  useEffect(() => {
    var outcome = false;
    if (label !== componentInstance.label) {
      outcome = true;
    }
    if (!outcome && componentInstance.settings && settings) {
      if (
        JSON.stringify(sortObjectByKeys(componentInstance.settings)) !==
        JSON.stringify(sortObjectByKeys(settings))
      ) {
        outcome = true;
      }
    }
    setIsChanged(outcome);
  }, [settings, label, componentInstance.label, componentInstance.settings]);

  function handleSettingChange(event) {
    const { name, value } = event.target;
    var settingKey = name.replace('settings.', '');
    var updateSettings = settings;
    if (value.length > 0 || typeof value === 'boolean') {
      updateSettings[settingKey].instance = value;
    } else {
      delete updateSettings[settingKey].instance;
    }
    setSettings({ ...updateSettings });
  }

  const handlePresetSelection = (selectedPreset) => {
    const settingsForThePreset = StorytimeTemplates.filter(
      (preset) => preset.displayName === selectedPreset
    )[0];
    let appliedSettings = structuredClone(settings);

    Object.keys(settings).forEach((key) => {
      appliedSettings[key]['instance'] = settingsForThePreset.settings[key];
    });
    setImportedSettingsValue({ applicationId, settings: appliedSettings });
  };
  const handlePresetImport = () => {
    setSettings(importedSettingsValue.settings);
    setPresetModalOpen(false);
  };

  const handleExport = () => {
    const settingsData = structuredClone({
      applicationId,
      settings,
    });
    Object.keys(settingsData.settings).forEach((key) => {
      if (!settingsData.settings[key]['instance']) {
        settingsData.settings[key]['instance'] =
          settingsData.settings[key]['default'];
      }
    });

    navigator.clipboard
      .writeText(JSON.stringify(settingsData))
      .then(() => {
        setToast(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const compareObjects = (type1, type2) => {
    const isValidStructure = (obj) => {
      return (
        obj &&
        typeof obj === 'object' &&
        obj.hasOwnProperty('type') &&
        obj.hasOwnProperty('default')
      );
    };

    for (let key in type1) {
      if (type1.hasOwnProperty(key)) {
        if (!type2.hasOwnProperty(key)) {
          return false;
        }
        if (!isValidStructure(type2[key])) {
          return false;
        }
        if (type1[key].type !== type2[key].type) {
          return false;
        }
      }
    }

    for (let key in type2) {
      if (type2.hasOwnProperty(key)) {
        if (!type1.hasOwnProperty(key)) {
          if (!isValidStructure(type2[key])) {
            return false;
          }
        }
      }
    }

    return true;
  };
  const getChangedDefaults = (type1, type2) => {
    const changedKeys = [];

    for (let key in type1) {
      if (type1.hasOwnProperty(key) && type2.hasOwnProperty(key)) {
        if (type1[key].default !== type2[key].default) {
          changedKeys.push(key);
        }
      }
    }

    return changedKeys;
  };
  const validateSettings = () => {
    if (typeof importedSettingsValue !== 'object') {
      return false;
    }
    if (!importedSettingsValue || !importedSettingsValue.applicationId) {
      return false;
    }
    if (!importedSettingsValue.settings) {
      return false;
    }
    if (importedSettingsValue.applicationId !== applicationId) {
      return false;
    }
    if (!compareObjects(settings, importedSettingsValue.settings)) {
      return false;
    }

    return true;
  };

  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleImport = () => {
    if (validateSettings()) {
      setSettings(importedSettingsValue.settings);
      let changedKeys = getChangedDefaults(
        settings,
        importedSettingsValue.settings
      );
      setChangedDefaultKeys(changedKeys);
      setImportModalOpen(false);
      return;
    } else {
      setImportError(true);
    }
  };
  const handleHide = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };
  function validateForm() {
    var result = true;
    if (label === '') {
      setError('Please enter a valid alias for your instance.');
      result = false;
    } else {
      setError();
    }
    return result;
  }

  function setDefault(key) {
    var updateSettings = settings;
    delete updateSettings[key].instance;
    setSettings({ ...settings });
  }

  async function updateApp() {
    if (validateForm()) {
      setWaiting(true);

      var instanceSettings = {};
      Object.entries(settings).forEach(([key, value]) => {
        if (value.hasOwnProperty('instance')) {
          instanceSettings[key] = value.instance;
        }
      });

      var data = {
        label,
        settings:
          Object.keys(instanceSettings).length > 0
            ? instanceSettings
            : undefined,
      };
      const url =
        type === 'application'
          ? `${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/apps/${params.appId}`
          : `${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/resources/${params.resourceId}`;

      axios
        .put(url, data, {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
        })
        .then((response) => {
          setComponentInstance(response.data);
          setLabel(response.data.label);
          setSettings(JSON.parse(JSON.stringify(response.data.settings)));
          onUpdate(response.data.label);
          setWaiting(false);
          setIsChanged(false);
        })
        .catch((error) => {
          setError(error);
          setWaiting(false);
        });
    }
  }

  return (
    <Container>
      <Dialog
        onClose={() => setImportModalOpen(false)}
        isOpen={isImportModalOpen}
        width={500}
        primaryCallToActionComponent={
          <Button label="Import" onClick={handleImport} variant="primary" />
        }
        tertiaryCallToActionComponent={
          <Button
            label="Cancel"
            onClick={() => setImportModalOpen(false)}
            variant="floating"
          />
        }
        title="Import settings to this application"
      >
        <span>Enter your exported settings JSON here</span>
        <TextField
          defaultValue=""
          errorMessage={
            isImportError
              ? 'Imported settings are not compatible with this application'
              : ''
          }
          isMultiline
          value={importedSettingsValue}
          onChange={(e) => {
            if (isValidJSON(e.target.value)) {
              setImportedSettingsValue(JSON.parse(e.target.value));
            } else {
              setImportError(true);
            }
          }}
        />
      </Dialog>
      <Dialog
        onClose={() => setPresetModalOpen(false)}
        isOpen={isPresetModalOpen}
        primaryCallToActionComponent={
          <Button
            label="Import"
            onClick={handlePresetImport}
            variant="primary"
          />
        }
        tertiaryCallToActionComponent={
          <Button
            label="Cancel"
            onClick={() => setPresetModalOpen(false)}
            variant="floating"
          />
        }
        title="Import Preset Settings for this application"
      >
        <Select
          defaultValue=""
          hint="Select a Preset"
          onChange={(e) => handlePresetSelection(e.target.value)}
          options={StorytimeTemplates.map((value) => value.displayName)}
        />
      </Dialog>

      <ToastStack>
        <Toast
          isVisible={toast}
          autoHideDuration={2000}
          onHide={handleHide}
          role="status"
          severity="info"
          text={'Settings copied to clipboard'}
        />
      </ToastStack>
      {error ? <ErrorMessage error={error} /> : null}
      <Form id="instanceConfig" noValidate>
        <InstanceLabel label={label} onChange={handleLabelChange} />
        {settings && hasUserFacingSetting(settings) ? (
          <InstanceSettings
            settings={settings}
            changedDefaultKeys={changedDefaultKeys}
            onChange={handleSettingChange}
            setSettings={setSettings}
            onRevertToDefault={setDefault}
          />
        ) : null}
        <div className="buttonContainer">
          {waiting && <CircularProgress />}
          <MenuButton
            buttonLabel="More actions"
            buttonVariant="secondary"
            menuAlignment="left"
          >
            <MenuItem
              onClick={() => {
                setImportModalOpen(true);
                setImportError(false);
              }}
            >
              Import Settings
            </MenuItem>
            <MenuItem onClick={handleExport}>Export Settings</MenuItem>
            {/* Use Presets only available for storytime */}
            {instance.label === 'Storytime' && (
              <MenuItem onClick={() => setPresetModalOpen(true)}>
                Use Presets
              </MenuItem>
            )}
          </MenuButton>
          <Button
            isDisabled={waiting || !isChanged}
            className="branded"
            type="submit"
            variant="primary"
            label="Update"
            onClick={() => updateApp()}
          ></Button>
        </div>
      </Form>
    </Container>
  );
};

export default InstanceConfiguration;
