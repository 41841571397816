import { TextField } from '@okta/odyssey-react-mui';
const SecretSetting = ({ name, instanceValue, readOnly, onChange }) => {
  return (
    <TextField
      id="settings"
      key={name}
      name={'settings.' + name}
      value={instanceValue ? instanceValue : ''}
      onChange={onChange}
      maxLength="1024"
      placeholder={'****** (default)'}
      type="password"
      isDisabled={readOnly}
      fluid
      inline
    />
  );
};

export default SecretSetting;
