import { useFlags } from 'flagsmith/react';
import React, { useEffect, useState } from 'react';
import Gravatar from 'gravatar';
import './CollaborationModal.css';
import { addCollaborator, removeCollaborator } from '../../services/DemoAPI';
import ErrorMessage from '../ErrorMessage';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
} from '@okta/odyssey-react-mui';
import Image from '../ui/Image/Image';
import { AddIcon, DeleteIcon } from '@okta/odyssey-react-mui/icons';

function CollaborationModal({
  entity,
  entityType,
  trigger,
  function_reloaddata,
  open,
  setOpen,
}) {
  const { getAccessTokenSilently } = useAuth0();

  const flags = useFlags(['collaboration']);
  const [hasChanges, setHasChanges] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [err, setErr] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  useEffect(() => {
    if (open) {
      setErr(null);
    }
    if (!open) {
      if (hasChanges) {
        function_reloaddata();
        setHasChanges(false);
      }
    }
  }, [open, function_reloaddata, hasChanges]);

  function getComponentRoute() {
    if (entityType === 'demonstration') {
      return entityType + '/' + entity.name;
    } else if (entityType === 'application') {
      return 'applications/' + entity.application_id;
    } else if (entityType === 'resource') {
      return 'resources/' + entity.resource_id;
    } else {
      return '';
    }
  }

  async function inviteCollaborator(value) {
    try {
      setWaiting(true);
      await addCollaborator(
        await getAccessTokenSilently(),
        getComponentRoute(),
        { email: value, role: 'owner' }
      );
      entity.collaborators.push({ principal: value });
      document.getElementById('addCollaboratorInput').value = '';
      setHasChanges(true);
    } catch (err) {
      setErr(err);
    } finally {
      setWaiting(false);
    }
  }

  async function deleteCollaborator(value) {
    try {
      setWaiting(true);
      await removeCollaborator(
        await getAccessTokenSilently(),
        getComponentRoute(),
        { email: value, role: 'owner' }
      );
      entity.collaborators = entity.collaborators.filter(
        (item) => item.principal !== value
      );
      setHasChanges(true);
    } catch (err) {
      setErr(err);
    } finally {
      setWaiting(false);
    }
  }

  if (flags.collaboration.enabled && entity?.collaborators) {
    return (
      <Dialog
        title={`Collaborators on ${entity.name}`}
        isOpen={open}
        onClose={() => setOpen(false)}
        primaryCallToActionComponent={
          <>
            {' '}
            {waiting && <CircularProgress />}
            <Button
              label="Done"
              onClick={() => setOpen(false)}
              isDisabled={waiting}
              variant="primary"
            />
          </>
        }
      >
        <div className="collaborationModal">
          <p>
            Users here will be able to see and modify <b>{entity.name}</b> in
            <a
              href={`https://builder.demo.okta.com`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              builder.demo.okta.com
            </a>
          </p>

          {/* Collaborators List */}
          {entity?.collaborators.map((collaborator) => (
            <div className="collaboratorContainer">
              <Image
                className="collaborator"
                size="tiny"
                src={Gravatar.url(collaborator.principal, {
                  protocol: 'https',
                  s: '100',
                  d: 'https://cdn.demo.okta.com/images/okta-icon.png',
                })}
                avatar
              />
              {collaborator.principal}
              {entity.collaborators.length > 1 && (
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteCollaborator(collaborator.principal)}
                  size="small"
                  variant="floating"
                />
              )}
            </div>
          ))}
          <h4>Invite Collaborator</h4>
          <div className="fieldContainer">
            <TextField
              id="addCollaboratorInput"
              placeholder="Enter email"
              type="email"
              hint="Add a collaborator by entering their email address."
              isDisabled={waiting}
              errorMessage={errorMessage}
            />
            <Button
              startIcon={<AddIcon />}
              isDisabled={waiting}
              onClick={() => {
                const email = document.getElementById(
                  'addCollaboratorInput'
                ).value;
                if (emailRegex.test(email)) {
                  setErrorMessage(null);
                  inviteCollaborator(email);
                } else {
                  setErrorMessage('Invalid Email');
                }
              }}
            />
          </div>
          {err && <ErrorMessage error={err}>{err}</ErrorMessage>}
        </div>
      </Dialog>
    );
  } else {
    return <span>{trigger}</span>;
  }
}

export default CollaborationModal;
