import React, { useState } from 'react';
import './ComponentSettings.css';
import { sortObjectByKeys } from '../util/SortObjectByKeys.js';
import TextSetting from './settings/TextSetting';
import SecretSetting from './settings/SecretSetting';
import BooleanSetting from './settings/BooleanSetting';
import SettingTypeSelector from './settings/SettingTypeSelector';
import Container from './ui/Container/Container.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TextField, Button } from '@okta/odyssey-react-mui';
import { AddIcon, CloseIcon } from '@okta/odyssey-react-mui/icons';
const ComponentSettings = ({ settings, allowEdit, setSettings }) => {
  const [newSettingType, setNewSettingType] = useState('text');
  const [newSettingName, setNewSettingName] = useState('');
  const [newSettingNameError, setNewSettingNameError] = useState();
  const [newSettingDefault, setNewSettingDefault] = useState('');

  settings = sortObjectByKeys(settings);

  function removeSetting(key) {
    var updateSettings = settings;
    delete updateSettings[key];
    setSettings({ ...settings });
  }

  function addNewSetting() {
    setNewSettingNameError();
    if (!newSettingName || newSettingName === '') {
      setNewSettingNameError('Please enter a name for your setting');
    } else {
      var updateSettings = settings;
      updateSettings[newSettingName] = {
        type: newSettingType,
        default: newSettingDefault,
      };
      setSettings({ ...updateSettings });
      setNewSettingType('text');
      setNewSettingDefault('');
      setNewSettingName('');
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case 'newSettingName':
        setNewSettingName(value);
        break;
      case 'settings.newSettingDefault':
        setNewSettingDefault(value);
        break;
      case 'newSettingType':
        setNewSettingType(value);
        if (value === 'bool') {
          setNewSettingDefault(true);
        }
        if (value === 'text' || value === 'secret') {
          setNewSettingDefault('');
        }
        break;
      default:
        var settingKey = name.replace('settings.', '');
        var updateSettings = settings;
        if (value.length > 0 || typeof value === 'boolean') {
          updateSettings[settingKey].default = value;
        } else {
          delete updateSettings[settingKey].default;
        }
        setSettings({ ...settings });
        break;
    }
  }

  return (
    <Container>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell sx={{ minWidth: '10%' }}>Setting Type</TableCell>
            <TableCell>Default Value</TableCell>
            <TableCell width={1}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(settings).map((key) => (
            <TableRow>
              <TableCell className="nameField">
                <span>{key}</span>
              </TableCell>

              <TableCell>
                <SettingTypeSelector
                  settingType={settings[key].type}
                  disabled={true}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell>
                {settings[key].type === 'bool' && (
                  <BooleanSetting
                    name={key}
                    instanceValue={settings[key].default}
                    onChange={handleChange}
                    readOnly={!allowEdit}
                  />
                )}
                {settings[key].type === 'secret' && (
                  <SecretSetting
                    name={key}
                    instanceValue={settings[key].default}
                    onChange={handleChange}
                    readOnly={!allowEdit}
                  />
                )}
                {settings[key].type === 'text' && (
                  <TextSetting
                    name={key}
                    instanceValue={settings[key].default}
                    onChange={handleChange}
                    readOnly={!allowEdit}
                  />
                )}
              </TableCell>
              <TableCell>
                {allowEdit && (
                  <div className="addButtonComponent">
                    <Button
                      className="rowAction"
                      variant="secondary"
                      label="Remove"
                      startIcon={<CloseIcon />}
                      color="red"
                      labelPosition="left"
                      onClick={() => removeSetting(key)}
                    />
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}

          {allowEdit && (
            <TableRow>
              <TableCell>
                <TextField
                  name="newSettingName"
                  value={newSettingName}
                  onChange={handleChange}
                  errorMessage={newSettingNameError}
                  maxLength="30"
                  placeholder="name"
                  fluid
                />
              </TableCell>
              <TableCell>
                <SettingTypeSelector onChange={handleChange} />
              </TableCell>
              <TableCell>
                {newSettingType === 'text' && (
                  <TextSetting
                    name="newSettingDefault"
                    instanceValue={newSettingDefault}
                    onChange={handleChange}
                    readOnly={!allowEdit}
                  />
                )}
                {newSettingType === 'secret' && (
                  <SecretSetting
                    name="newSettingDefault"
                    instanceValue={newSettingDefault}
                    onChange={handleChange}
                    readOnly={!allowEdit}
                  />
                )}
                {newSettingType === 'bool' && (
                  <BooleanSetting
                    name="newSettingDefault"
                    instanceValue={newSettingDefault}
                    onChange={handleChange}
                    readOnly={!allowEdit}
                  />
                )}
              </TableCell>

              <TableCell>
                <div className="addButtonComponent">
                  <Button
                    sx={{ marginTop: '23px' }}
                    label="Add"
                    startIcon={<AddIcon />}
                    labelPosition="left"
                    onClick={() => addNewSetting()}
                  />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
};
export default ComponentSettings;
