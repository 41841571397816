import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PendingIcon from '@mui/icons-material/Pending';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import HelpIcon from '@mui/icons-material/Help';
const StateLabel = ({ state }) => {
  switch (state) {
    case 'active':
      return (
        <div
          style={{
            display: 'flex',
            'align-items': 'center',
            gap: '0.3rem',
          }}
        >
          <CheckCircleIcon color="success" /> Active
        </div>
      );
    case 'deploying':
      return (
        <div
          style={{
            display: 'flex',
            'align-items': 'center',
            gap: '0.3rem',
          }}
        >
          <HourglassBottomIcon /> Deploying
        </div>
      );
    case 'queued':
      return (
        <div
          style={{
            display: 'flex',
            'align-items': 'center',
            gap: '0.3rem',
          }}
        >
          <PendingIcon loading /> Queued
        </div>
      );
    case 'error':
      return (
        <div>
          <DoNotDisturbOffIcon color="error" /> Error
        </div>
      );
    default:
      return (
        <div
          style={{
            display: 'flex',
            'align-items': 'center',
            gap: '0.3rem',
          }}
        >
          <HelpIcon /> {state}
        </div>
      );
  }
};
export default StateLabel;
