import { TextField } from '@okta/odyssey-react-mui';
const TextSetting = ({
  name,
  instanceValue,
  defaultValue = '',
  readOnly = false,
  onChange,
}) => {
  return (
    <TextField
      id="settings"
      key={name}
      name={'settings.' + name}
      value={instanceValue ? instanceValue : ''}
      onChange={onChange}
      maxLength="1024"
      placeholder={defaultValue + ' (default)'}
      isDisabled={readOnly}
      fluid
      inline
    />
  );
};

export default TextSetting;
