import React, { useState } from 'react';
import { useOpportunityContext } from '../OpportunityContext';
import './OpportunityPicker.css';
import { Autocomplete, Button, Form } from '@okta/odyssey-react-mui';
import { AddIcon } from '@okta/odyssey-react-mui/icons';

const OpportunityPicker = ({
  allowEdit,
  updateOpportunity,
  dismissOnChange = false,
  existingOpps = [],
}) => {
  const { opportunities } = useOpportunityContext();
  const [selected, setSelected] = useState(null);
  let optionKeyToValueMap = [];
  function buildOptions(opportunities) {
    const options = [];
    if (dismissOnChange) {
      options.push({
        label: '-- Skip --',
        value: 'noop',
      });
    }
    opportunities.forEach((element) => {
      if (!existingOpps.find((opp) => opp === element.opportunity_id)) {
        options.push({
          label: element.name,
          value: element.opportunity_id,
        });
      }
    });
    options.forEach((option) => {
      optionKeyToValueMap.push(option.value);
    });

    return options;
  }

  function onChange(event) {
    event.stopPropagation();
    const { value } = event.target;
    setSelected(optionKeyToValueMap[value]);
    if (dismissOnChange) {
      updateOpportunity({
        target: {
          id: 'opportunity',
          value: optionKeyToValueMap[value],
        },
      });
    }
  }

  function submit() {
    updateOpportunity({
      target: {
        id: 'opportunity',
        value: selected,
      },
    });
  }

  if (dismissOnChange) {
    return (
      <Autocomplete
        placeholder="Select Opportunity"
        id="opportunities"
        name="opportunities"
        onChange={onChange}
        options={buildOptions(opportunities)}
        isReadOnly={!allowEdit}
        isDisabled={!allowEdit}
      />
    );
  } else {
    return (
      <Form inline fluid>
        <div className="formGroup">
          <Autocomplete
            placeholder="Select Opportunity"
            id="opportunities"
            name="opportunities"
            className="fillFlexParent"
            onChange={onChange}
            options={buildOptions(opportunities)}
            isReadOnly={!allowEdit}
            isDisabled={!allowEdit}
            style={{ 'flow-grow': 4 }}
          />
          {allowEdit ? (
            <Button
              startIcon={<AddIcon />}
              className="cta"
              onClick={submit}
            ></Button>
          ) : (
            <Button
              startIcon={<AddIcon />}
              loading
              className="cta"
              isDisabled
            ></Button>
          )}
        </div>
      </Form>
    );
  }
};
export default OpportunityPicker;
