import { useNavigate } from 'react-router-dom';
import './NavigationButton.css';
import { ChevronLeftIcon } from '@okta/odyssey-react-mui/icons';

const NavigationButton = ({ destination, msg = 'back' }) => {
  const navigate = useNavigate();
  function handleOnClick(path) {
    navigate(path);
  }

  return (
    <span className="navButton" onClick={() => handleOnClick(destination)}>
      {msg === 'back' ? <ChevronLeftIcon /> : null}
      {msg}
    </span>
  );
};
export default NavigationButton;
