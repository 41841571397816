import React, { useState, useEffect, useCallback } from 'react';
import './demos.css';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Config from '../Config';
import ErrorMessage from './ErrorMessage';
import CategoryLabel from './CategoryLabel';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'flagsmith/react';
import Header from './ui/Header/Header';
import Image from './ui/Image/Image';
import { Callout, CircularProgress } from '@okta/odyssey-react-mui';
import { Button } from '@okta/odyssey-react-mui';
import { AddIcon } from '@okta/odyssey-react-mui/icons';
import CardGroup from './ui/CardGroup/CardGroup';
import { Card } from '@okta/odyssey-react-mui';
import './Applications.css';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
const Applications = () => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState();
  const [error, setError] = useState(null);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['app_limits']);

  const getApplications = useCallback(async () => {
    setError();
    if (isAuthenticated) {
      axios
        .get(Config.resourceServer.demoAPI + '/applications', {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
          params: { owner: user.email },
        })
        .then((response) => {
          response.data.sort((a, b) => a.name.localeCompare(b.name));
          setApplications(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setApplications([]);
    }
  }, [getAccessTokenSilently, isAuthenticated, setApplications, user.email]);

  useEffect(() => {
    if (isAuthenticated) {
      getApplications();
    } else {
      setApplications([]);
    }
  }, [
    getApplications,
    isAuthenticated,
    getAccessTokenSilently,
    setApplications,
  ]);

  function handleOnClick(path) {
    navigate(path);
  }

  const bannerContent = (
    <>
      <p>
        Add your own custom applications to the demo library to easily reuse
        components between demos or build a true custom experience.
      </p>
      <p>
        Learn how to get started building custom demo apps with our docs
        <a target="_blank" rel="noreferrer" href="https://docs.demo.okta.com/">
          {' '}
          here
        </a>
        .
      </p>
    </>
  );
  return (
    <div className="appComponent">
      <Header className="contentHeader">Your Applications</Header>

      {applications ? (
        <div className="mainContainer">
          <Button
            className="createActionButton branded"
            label="New Application"
            startIcon={<AddIcon />}
            variant="primary"
            onClick={() => handleOnClick('/app')}
            isDisabled={
              flags.app_limits.enabled &&
              applications.length >= flags.app_limits.value
            }
          />
          {flags.app_limits.enabled &&
            applications.length >= flags.app_limits.value && (
              <Callout
                severity="error"
                text="You've reached the maximum number of applications allowed."
              />
            )}
          {applications.length !== 0 ? (
            <CardGroup className="hoverDarkerCard">
              {applications.map((application) => (
                <Card
                  sx={{ minWidth: '300px' }}
                  key={application.id}
                  onClick={() =>
                    handleOnClick(`/app/${application.application_id}`)
                  }
                  title={application.name}
                  description={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                      }}
                    >
                      <Image
                        floated="right"
                        size="tiny"
                        className="identicon"
                        src={
                          application.logo
                            ? application.logo
                            : 'https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/icon-applications.svg'
                        }
                      />

                      <CategoryLabel category={application.category} />

                      {new URL(application.baseAddress).host}
                    </div>
                  }
                ></Card>
              ))}
            </CardGroup>
          ) : (
            <Callout
              role="status"
              severity="info"
              text={bannerContent}
              title="Applications"
            />
          )}
        </div>
      ) : error ? (
        <ErrorMessage error={error} retryAction={getApplications} />
      ) : (
        !error && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={150} height={50} />
              </Stack>
            </div>
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={250} height={210} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={250} height={210} />
              </Stack>
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default Applications;
