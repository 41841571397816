import { Select } from '@okta/odyssey-react-mui';
const AWSRegionConstants = [
  {
    key: 'us-east-1',
    text: 'US East (N. Virginia)',
    value: 'us-east-1',
  },
  {
    key: 'us-west-1',
    text: 'US West (N. California)',
    value: 'us-west-1',
  },
  {
    key: 'us-west-2',
    text: 'US West (Oregon)',
    value: 'us-west-2',
  },
  {
    key: 'eu-west-1',
    text: 'Europe (Ireland)',
    value: 'eu-west-1',
  },
  {
    key: 'eu-central-1',
    text: 'Europe (Frankfurt)',
    value: 'eu-central-1',
  },
  {
    key: 'ap-northeast-1',
    text: 'Asia Pacific (Tokyo)',
    value: 'ap-northeast-1',
  },
];

const AWSRegionSetting = ({
  name,
  instanceValue,
  defaultValue,
  readOnly = false,
  onChange,
}) => {
  return (
    <Select
      id="settings"
      key={name}
      name={'settings.' + name}
      value={instanceValue !== undefined ? instanceValue : null}
      onChange={onChange}
      options={AWSRegionConstants}
      placeholder={
        (defaultValue !== undefined ? defaultValue.toString() : '') +
        ' (default)'
      }
      readOnly={readOnly}
      isDisabled={readOnly}
      selection
      fluid
      inline
    />
  );
};

export default AWSRegionSetting;
