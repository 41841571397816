import { useState } from 'react';
import Container from '../ui/Container/Container';
import Header from '../ui/Header/Header';
import Divider from '../ui/Divider/Divider';
import TemplatePicker from '../TemplatePicker';
import { getTemplateSettings } from '../../util/StorytimeTemplates';
import { Form, Button } from '@okta/odyssey-react-mui';

const StorytimePrompt = ({ step, demoName, func_complete }) => {
  const [waiting, setWaiting] = useState(false);
  const [settings, setSettings] = useState();

  async function submit() {
    setWaiting(true);
    var action = step;
    action.settings = settings;
    func_complete(step);
  }

  async function skip() {
    setWaiting(true);
    func_complete(step);
  }

  function handleChange(event) {
    setSettings(getTemplateSettings(event.target.value));
  }

  return (
    <Container>
      <Container>
        <Header>Storytime Preset</Header>
        <Container>
          <p>
            Configure the Storytime demonstration for your usecase by selecting
            from the presets below to apply cosmetic and functional
            configuration changes.
          </p>
        </Container>
        <Divider hidden />
        <Form
          noValidate
          formActions={
            <>
              {' '}
              <Button
                isDisabled={waiting}
                className="branded"
                type="submit"
                onClick={submit}
                label="Apply"
              ></Button>
              <Button
                isDisabled={waiting}
                className=""
                onClick={skip}
                label="Skip"
                variant="secondary"
              ></Button>
            </>
          }
        >
          <TemplatePicker
            allowEdit={true}
            updateApplication={handleChange}
          ></TemplatePicker>
        </Form>
      </Container>
    </Container>
  );
};
export default StorytimePrompt;
