import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import './FormSkeleton.css';
const FormSkeleton = () => {
  return (
    <div className="mainSkeleton">
      <div className="headerSkeletonStack">
        <div className="headerSkeleton">
          {' '}
          <div className="headingSkeleton">
            <Stack spacing={1}>
              <Skeleton variant="text" width={70} height={20} />
            </Stack>
          </div>
          <Stack spacing={1}>
            <Skeleton variant="text" width={150} height={20} />
          </Stack>
        </div>
        <div className="headerSkeleton">
          {' '}
          <div className="headingSkeleton">
            <Stack spacing={1}>
              <Skeleton variant="text" width={70} height={20} />
            </Stack>
          </div>
          <Stack spacing={1}>
            <Skeleton variant="text" width={150} height={20} />
          </Stack>
        </div>
        <div className="headerSkeleton">
          {' '}
          <div className="headingSkeleton">
            <Stack spacing={1}>
              <Skeleton variant="text" width={70} height={20} />
            </Stack>
          </div>
          <Stack spacing={1}>
            <Skeleton variant="text" width={150} height={20} />
          </Stack>
        </div>
        <div className="headerSkeleton">
          {' '}
          <div className="headingSkeleton">
            <Stack spacing={1}>
              <Skeleton variant="text" width={70} height={20} />
            </Stack>
          </div>
          <Stack spacing={1}>
            <Skeleton variant="text" width={150} height={20} />
          </Stack>
        </div>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={330} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="dataSkeleton">
        <Stack spacing={1}>
          <Skeleton variant="text" width={70} height={20} />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={450} height={50} />
        </Stack>
      </div>
      <div className="formSkeleton"></div>
    </div>
  );
};
export default FormSkeleton;
