import './IdpTypeLabel.css';
import IdpType from './IdpType';
import { Box } from '@okta/odyssey-react-mui';
import Image from './ui/Image/Image';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
const IdpTypeLabel = ({ idps, idpid }) => {
  var result = idps.find((obj) => {
    return obj.idp_id === idpid;
  });
  if (result) {
    switch (result.type) {
      case 'customer-identity':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center !important',
              gap: '.25rem',
              marginBottom: '0.75rem',
            }}
          >
            <Image
              className="idpIcon"
              src="https://cdn.demo.okta.com/images/okta/CIC/icon_black.png"
            />{' '}
            <IdpType idp={result} />
          </Box>
        );
      case 'okta-customer-identity':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center !important',
              gap: '.25rem',
              marginBottom: '0.75rem',
            }}
          >
            <Image
              className="idpIcon"
              src="https://cdn.demo.okta.com/images/okta/WIC/icon_black.png"
            />{' '}
            <IdpType idp={result} />
          </Box>
        );
      case 'workforce-identity':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center !important',
              gap: '.25rem',
              marginBottom: '0.75rem',
            }}
          >
            <Image
              className="idpIcon"
              src="https://cdn.demo.okta.com/images/okta/WIC/icon_black.png"
            />{' '}
            <IdpType idp={result} />
          </Box>
        );
      default:
        break;
    }
  } else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center !important',
          gap: '.25rem',
          marginBottom: '0.75rem',
        }}
      >
        <HelpOutlineOutlinedIcon />
        Unknown
      </div>
    );
  }
};
export default IdpTypeLabel;
