import { useState } from 'react';
import { TextField } from '@okta/odyssey-react-mui';
const InstanceLabel = ({ label, onChange }) => {
  const [labelError, setLabelError] = useState();

  function validateForm(event) {
    if (event.target.value === '') {
      setLabelError('Please enter an alias for your instance.');
    } else {
      setLabelError();
    }
    onChange(event.target.value);
  }

  return (
    <TextField
      id="label"
      key="label"
      label="Label"
      value={label}
      onChange={validateForm}
      errorMessage={labelError}
      onBlur={validateForm}
      maxLength="30"
    />
  );
};

export default InstanceLabel;
