import { WarningIcon, ResetIcon } from '@okta/odyssey-react-mui/icons';
import { Button, Tooltip } from '@okta/odyssey-react-mui';
import BooleanSetting from '../settings/BooleanSetting';
import TextSetting from '../settings/TextSetting';
import SecretSetting from '../settings/SecretSetting';
import { sortObjectByKeys } from '../../util/SortObjectByKeys';
import Container from '../ui/Container/Container';
import Header from '../ui/Header/Header';
import './InstanceSettings.css';
const InstanceSettings = ({
  settings,
  onChange,
  onRevertToDefault,
  changedDefaultKeys,
}) => {
  const protectedFields = [
    'awsAccountId',
    'awsRegion',
    'InitialInboundIpAddress',
  ];

  return (
    <Container>
      <div>
        <Header as="h3">Instance Settings</Header>
      </div>

      <div id="custom-table">
        {Object.keys(sortObjectByKeys(settings)).map((key, index) => (
          <>
            {!key.startsWith('_') && (
              <div className={index % 2 === 0 ? 'row alternate' : 'row'}>
                <div className="cell">{key}</div>
                <div className="cell">
                  {settings[key].type === 'bool' && (
                    <BooleanSetting
                      name={key}
                      instanceValue={settings[key].instance}
                      defaultValue={settings[key].default}
                      onChange={onChange}
                    />
                  )}
                  {settings[key].type === 'text' && (
                    <TextSetting
                      name={key}
                      instanceValue={settings[key].instance}
                      defaultValue={settings[key].default}
                      readOnly={protectedFields.includes(key)}
                      disabled={protectedFields.includes(key)}
                      onChange={onChange}
                    />
                  )}
                  {settings[key].type === 'secret' && (
                    <SecretSetting
                      name={key}
                      instanceValue={settings[key].instance}
                      onChange={onChange}
                    />
                  )}
                </div>
                <div className="cell">
                  {settings[key].hasOwnProperty('instance') &&
                    !protectedFields.includes(key) && (
                      <Button
                        key={key}
                        label="Use Default"
                        startIcon={<ResetIcon />}
                        variant="secondary"
                        onClick={() => onRevertToDefault(key)}
                      />
                    )}
                </div>
                <div className="cell">
                  {changedDefaultKeys.includes(key) ? (
                    <Tooltip
                      ariaType="description"
                      placement="top"
                      text="Default Value for this setting has changed. Your application may not work as expected. "
                    >
                      <Button
                        ariaLabel="Warning"
                        startIcon={<WarningIcon />}
                        variant="floating"
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </Container>
  );
};

export default InstanceSettings;
