import { Callout, Button } from '@okta/odyssey-react-mui';
import React from 'react';
import Container from './ui/Container/Container';
import { RefreshIcon } from '@okta/odyssey-react-mui/icons';
const ErrorMessage = ({ error, retryAction }) => {
  const validationErrors = error?.response?.data?.validationErrors;

  function displayValidationError(error) {
    return <li>{error?.message}</li>;
  }

  let displayMsg = error;
  if (error?.response?.data?.message) {
    displayMsg = error?.response?.data?.message;
  } else if (error?.message) {
    displayMsg = error?.message;
  }
  return (
    <>
      <Callout
        role="alert"
        severity="error"
        title="There was a problem with your request"
      >
        <p>{displayMsg}</p>
        <React.Fragment key=".0">
          {validationErrors?.length > 0 && (
            <Container className="scrollable">
              <ul>
                {validationErrors.map((error) => displayValidationError(error))}
              </ul>
            </Container>
          )}
        </React.Fragment>
        <p>
          If the problem persists please reach out to the Demo Engineering team.
        </p>
        {retryAction ? (
          <Button
            className="brandColor"
            compact
            startIcon={<RefreshIcon />}
            onClick={retryAction}
            label="Retry"
          ></Button>
        ) : null}
      </Callout>
    </>
  );
};
export default ErrorMessage;
