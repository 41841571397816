import { PasswordField, TextField } from '@okta/odyssey-react-mui';
import { CopyableField } from '../fields';
import Container from '../ui/Container/Container';
import Header from '../ui/Header/Header';
import { Form } from '@okta/odyssey-react-mui';
const InstanceCredentials = ({ credentials }) => {
  return (
    <Container>
      {credentials.discoveryUrl && (
        <Container>
          <Header as="h3">OAuth Client</Header>
          <Form>
            <CopyableField
              label="Discovery URI"
              value={credentials.discoveryUrl}
              childField={
                <TextField
                  label="Discovery URL"
                  defaultValue={credentials.discoveryUrl}
                  isReadOnly
                />
              }
            />
            <CopyableField
              label="Client ID"
              value={credentials.clientId}
              childField={
                <TextField
                  label="Client ID"
                  defaultValue={credentials.clientId}
                  isReadOnly
                />
              }
            />
            <CopyableField
              label="Client Secret"
              value={credentials.clientSecret}
              childField={
                <PasswordField
                  label="Client Secret"
                  defaultValue={credentials.clientSecret}
                  hasShowPassword
                  isReadOnly
                />
              }
            />
          </Form>
        </Container>
      )}
      {credentials.username && (
        <Container>
          <Header as="h3">User login</Header>
          <Form>
            <CopyableField
              label="Username"
              value={credentials.username}
              childField={
                <TextField
                  label="Username"
                  defaultValue={credentials.username}
                  isReadOnly
                />
              }
            />
            <CopyableField
              label="Password"
              value={credentials.password}
              childField={
                <PasswordField
                  label="Password"
                  defaultValue={credentials.password}
                  hasShowPassword
                  isReadOnly
                />
              }
            />
          </Form>
        </Container>
      )}
      {credentials.apiKey && (
        <Container>
          <Header as="h3">API</Header>
          <Form>
            <CopyableField
              label="API Key"
              value={credentials.apiKey}
              childField={
                <PasswordField
                  label="API Key"
                  defaultValue={credentials.apiKey}
                  hasShowPassword
                  isReadOnly
                />
              }
            />
          </Form>
        </Container>
      )}
    </Container>
  );
};

export default InstanceCredentials;
