import { useState, useEffect } from 'react';
import flagsmith from 'flagsmith';
import Container from '../ui/Container/Container';
import Header from '../ui/Header/Header';
import Divider from '../ui/Divider/Divider';
import { Button, Surface } from '@okta/odyssey-react-mui';
const Oktaprise = ({ step, func_complete }) => {
  const [waiting, setWaiting] = useState(false);

  async function submit() {
    setWaiting(true);
    func_complete(step);
  }

  async function skip() {
    setWaiting(true);
    func_complete();
  }

  useEffect(() => {
    if (flagsmith.getTrait('connection') !== 'employee') {
      func_complete();
    }
  }, [func_complete]);

  return (
    <Container>
      <Container>
        <Header>Oktaprise</Header>
        <Container>
          <p>
            The power of Okta's integration network is unbounded; with
            integrations that enhance security, productivity, and beyond.
            Showcasing these integrations is core to Okta's value, however some
            can be complex to setup independently. Configuring SSO with the
            Oktaprise shared demonstration environment will enable you to be
            able to include demonstrations of our technology partners; all from
            within your tenant; with little or no configuration required.
          </p>
        </Container>
        <Divider hidden />
        <Container>
          <Button
            isDisabled={waiting}
            className="branded"
            onClick={submit}
            label="Connect"
          ></Button>
          <Button
            isDisabled={waiting}
            className=""
            onClick={skip}
            label="Skip"
          ></Button>
        </Container>
      </Container>
    </Container>
  );
};
export default Oktaprise;
