import React from 'react';
import PropTypes from 'prop-types';
import './Image.css';

const Image = ({
  src,
  alt,
  className,
  size,
  centered,
  rounded,
  circular,
  bordered,
  style,
}) => {
  const imageClass = `
    custom-image
    ${size ? `custom-image--${size}` : ''}
    ${centered ? 'custom-image--centered' : ''}
    ${rounded ? 'custom-image--rounded' : ''}
    ${circular ? 'custom-image--circular' : ''}
    ${bordered ? 'custom-image--bordered' : ''}
    ${className || ''}
  `.trim();

  return (
    <img src={src} alt={alt || 'image'} className={imageClass} style={style} />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf([
    'tiny',
    'small',
    'medium',
    'large',
    'big',
    'huge',
    'massive',
  ]),
  centered: PropTypes.bool,
  rounded: PropTypes.bool,
  circular: PropTypes.bool,
  bordered: PropTypes.bool,
  style: PropTypes.object,
};

Image.defaultProps = {
  alt: '',
  className: '',
  size: null,
  centered: false,
  rounded: false,
  circular: false,
  bordered: false,
  style: {},
};

export default Image;
