import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from '../Config';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import CategoryLabel from '../components/CategoryLabel';
import NavigationButton from '../components/NavigationButton';
import InstanceDetails from '../components/instance/InstanceDetails';
import { sortObjectByKeys } from '../util/SortObjectByKeys';
import {
  BreadcrumbList,
  Callout,
  CircularProgress,
  Surface,
  Button,
  Dialog,
} from '@okta/odyssey-react-mui';
import { detachResourceFromDemonstration } from '../services/DemoAPI';
import { useNavigate } from 'react-router';
import Header from '../components/ui/Header/Header';
import Container from '../components/ui/Container/Container';
import { CheckIcon, CloseIcon } from '@okta/odyssey-react-mui/icons';
const ResourceInstanceDetails = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [resource, setResource] = useState(null);
  const [label, setLabel] = useState();
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [detachModalOpen, setDetachModalOpen] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getResource = useCallback(async () => {
    setResource();
    setLabel();
    axios
      .get(
        `${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/resources/${params.resourceId}`,
        {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
        }
      )
      .then((response) => {
        response.data.settings = sortObjectByKeys(response.data.settings);
        setResource(response.data);
        setLabel(response.data.label);
      })
      .catch((error) => {
        setError(error);
      });
  }, [
    setResource,
    setLabel,
    getAccessTokenSilently,
    params.resourceId,
    params.demoName,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getResource();
    } else {
      setResource();
      setLabel();
    }
  }, [getResource, isAuthenticated, getAccessTokenSilently, setResource]);

  async function detachInstance() {
    setWaiting(true);
    detachResourceFromDemonstration(
      await getAccessTokenSilently(),
      params.demoName,
      params.resourceId
    )
      .then(() => {
        navigate('/demo/' + params.demoName);
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Surface className="appComponent">
      <BreadcrumbList>
        <NavigationButton destination={`/`} msg="Demos" />
        <NavigationButton
          destination={`/demo/${params.demoName}`}
          msg={params.demoName}
        />
        <i href="">{resource ? resource.label : params.resourceId}</i>
      </BreadcrumbList>

      {resource ? (
        <Container>
          <Dialog
            onClose={() => setDetachModalOpen(false)}
            title={`Are you sure you want to detach ${label}?`}
            onOpen={() => setDetachModalOpen(true)}
            isOpen={detachModalOpen}
            primaryCallToActionComponent={
              <Button
                variant="danger"
                onClick={() => {
                  setDetachModalOpen(false);
                  detachInstance();
                }}
                label="Detach"
                startIcon={<CheckIcon />}
              ></Button>
            }
            secondaryCallToActionComponent={
              <Button
                onClick={() => setDetachModalOpen(false)}
                label="Cancel"
                variant="secondary"
                startIcon={<CloseIcon />}
              ></Button>
            }
          >
            This action will remove the configuration of this component from
            your IDP and any settings changes you have made will be lost.
          </Dialog>
          <div className="componentHeader">
            <div>
              <Header className="contentHeader">{label}</Header>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {waiting ? <CircularProgress active inline /> : null}
              <Button
                floated="right"
                isDisabled={waiting}
                type="button"
                color="red"
                variant="danger"
                label="Detach"
                onClick={() => setDetachModalOpen(true)}
              >
                Detach
              </Button>
            </div>
          </div>

          {resource.deployedVersion < resource.latestVersion && (
            <Callout
              severity="info"
              title="New version available"
              text="An updated version of this resource is available, features and behaviour of this resource may differ based on your deployed version. To update to the latest version detach and reattach this resource."
            />
          )}

          <CategoryLabel category={resource.category} />
          {resource ? (
            <InstanceDetails
              instance={resource}
              type="resource"
              onUpdate={setLabel}
              onError={setError}
            />
          ) : (
            <CircularProgress active inline />
          )}
        </Container>
      ) : (
        <Header className="contentHeader">
          {params.demoName}: Resource Instance
        </Header>
      )}
      {error ? <ErrorMessage error={error} /> : null}
    </Surface>
  );
};
export default ResourceInstanceDetails;
