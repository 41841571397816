import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from '../Config';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import CategoryLabel from '../components/CategoryLabel';
import NavigationButton from '../components/NavigationButton';
import { sortObjectByKeys } from '../util/SortObjectByKeys.js';
import InstanceDetails from '../components/instance/InstanceDetails';
import {
  BreadcrumbList,
  Callout,
  Surface,
  Button,
  CircularProgress,
  Dialog,
} from '@okta/odyssey-react-mui';
import { useNavigate } from 'react-router';
import { detachAppFromDemonstration } from '../services/DemoAPI.js';
import Container from '../components/ui/Container/Container.js';
import Header from '../components/ui/Header/Header.js';
import './AppInstanceDetails.css';
import { CheckIcon, CloseIcon } from '@okta/odyssey-react-mui/icons';
const AppInstanceDetails = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [application, setApplication] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);
  const [label, setLabel] = useState();
  const [detachModalOpen, setDetachModalOpen] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getApp = useCallback(async () => {
    setApplication();
    setLabel();
    axios
      .get(
        `${Config.resourceServer.demoAPI}/demonstration/${params.demoName}/apps/${params.appId}`,
        {
          headers: {
            Authorization: 'Bearer ' + (await getAccessTokenSilently()),
          },
        }
      )
      .then((response) => {
        response.data.settings = sortObjectByKeys(response.data.settings);
        setApplication(response.data);
        setLabel(response.data.label);
      })
      .catch((error) => {
        setError(error);
      });
  }, [
    setApplication,
    setLabel,
    getAccessTokenSilently,
    params.appId,
    params.demoName,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getApp();
    } else {
      setApplication();
      setLabel();
    }
  }, [getApp, isAuthenticated, getAccessTokenSilently, setApplication]);

  async function detachInstance() {
    setWaiting(true);
    detachAppFromDemonstration(
      await getAccessTokenSilently(),
      params.demoName,
      params.appId
    )
      .then(() => {
        navigate('/demo/' + params.demoName);
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Surface className="appComponent">
      <BreadcrumbList>
        <NavigationButton destination={`/`} msg="Demos" />
        <NavigationButton
          destination={`/demo/${params.demoName}`}
          msg={params.demoName}
        />
        <i href="">{application ? application.label : params.appId}</i>
      </BreadcrumbList>
      <Container>
        {application ? (
          <Container>
            <Dialog
              onClose={() => setDetachModalOpen(false)}
              title={`Are you sure you want to detach ${label}?`}
              onOpen={() => setDetachModalOpen(true)}
              isOpen={detachModalOpen}
              primaryCallToActionComponent={
                <Button
                  variant="danger"
                  onClick={() => {
                    setDetachModalOpen(false);
                    detachInstance();
                  }}
                  label="Detach"
                  startIcon={<CheckIcon />}
                ></Button>
              }
              secondaryCallToActionComponent={
                <Button
                  onClick={() => setDetachModalOpen(false)}
                  label="Cancel"
                  variant="secondary"
                  startIcon={<CloseIcon />}
                ></Button>
              }
            >
              This action will remove the configuration of this component from
              your IDP and any settings changes you have made will be lost.
            </Dialog>
            <div className="componentHeader">
              <div>
                <Header className="contentHeader">{label}</Header>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                {waiting ? <CircularProgress active inline /> : null}
                <Button
                  floated="right"
                  isDisabled={waiting}
                  type="button"
                  color="red"
                  variant="danger"
                  label="Detach"
                  onClick={() => setDetachModalOpen(true)}
                >
                  Detach
                </Button>
              </div>
            </div>
            {application.deployedVersion < application.latestVersion && (
              <Callout
                severity="info"
                title="New version available"
                text="An updated version of this application is available, features and behaviour of this application may differ based on your deployed version. To update to the latest version detach and reattach this application."
              />
            )}

            <CategoryLabel category={application.category} />
            {application ? (
              <InstanceDetails
                instance={application}
                type="application"
                onUpdate={setLabel}
                onError={setError}
              />
            ) : (
              <CircularProgress active inline />
            )}
          </Container>
        ) : (
          <Container>
            <Header className="contentHeader">
              {params.demoName}: Application Instance
            </Header>
            <CircularProgress active inline />
          </Container>
        )}
        {error ? <ErrorMessage error={error} /> : null}
      </Container>
    </Surface>
  );
};
export default AppInstanceDetails;
