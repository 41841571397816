import Image from './ui/Image/Image';
const IdpTypeGraphic = ({ type }) => {
  switch (type) {
    case 'customer-identity':
      return (
        <Image
          size="tiny"
          src="https://cdn.demo.okta.com/images/okta/CIC/icon_black.png"
          style={{ float: 'right', margin: '10px' }}
        />
      );
    case 'okta-customer-identity':
      return (
        <Image
          style={{ float: 'right', margin: '10px' }}
          size="tiny"
          src="https://cdn.demo.okta.com/images/okta/WIC/icon_black.png"
        />
      );
    case 'workforce-identity':
      return (
        <Image
          style={{ float: 'right', margin: '10px' }}
          size="tiny"
          src="https://cdn.demo.okta.com/images/okta/WIC/icon_black.png"
        />
      );
    default:
      break;
  }
};
export default IdpTypeGraphic;
