import { Select } from '@okta/odyssey-react-mui';
import { SettingTypeOptions } from '../ComponentSettingsConstants';

const SettingTypeSelector = ({ settingType, disabled = false, onChange }) => {
  return (
    <Select
      name="newSettingType"
      onChange={onChange}
      options={SettingTypeOptions}
      placeholder="type"
      value={settingType}
      isDisabled={disabled}
      selection
      fluid
    />
  );
};

export default SettingTypeSelector;
