import { useState } from 'react';
import Container from '../ui/Container/Container';
import Header from '../ui/Header/Header';
import Divider from '../ui/Divider/Divider';
import { Button, Form, TextField } from '@okta/odyssey-react-mui';
const BrandingPrompt = ({ step, demoName, func_complete }) => {
  const [waiting, setWaiting] = useState(false);
  const [uri, setUri] = useState('https://okta.com');

  async function submit() {
    setWaiting(true);
    var action = step;
    action.settings = { prospectUrl: uri };
    func_complete(step);
  }

  async function skip() {
    setWaiting(true);
    func_complete();
  }

  function validateForm() {}

  function handleChange(event) {
    setUri(event.target.value);
  }

  return (
    <Container>
      <Container>
        <Header>Demonstration Branding</Header>
        <Container>
          First impressions are important. Providing an Identity experience that
          is seamless yet familar goes a long way in enabling your users to meet
          their business goals while ensuring a high level of security. Using
          images, fonts and colours that tie together in an already established
          user experience accelerates onboarding and migration to a new Identity
          Solution. Applying branding that is sourced from your Company's
          website provides an initial look and feel of familarity to your
          Identity Provider. This component sets a customised logo, colour
          schemes and other UI elements that align to an existing theme for a
          unified and seamless user experience.
        </Container>
        <Divider hidden />
        <Form
          noValidate
          formActions={
            <>
              {' '}
              <Button
                isDisabled={waiting}
                className="branded"
                type="submit"
                onClick={submit}
                label="Apply"
              ></Button>
              <Button
                isDisabled={waiting}
                className=""
                variant="secondary"
                onClick={skip}
                label="Skip"
              ></Button>
            </>
          }
        >
          <TextField
            id="name"
            label={<label>Company Website</label>}
            value={uri}
            onChange={handleChange}
            type="url"
            onKeyUp={validateForm}
            style={{ textTransform: 'lowercase' }}
          />
        </Form>
      </Container>
    </Container>
  );
};
export default BrandingPrompt;
