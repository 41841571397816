import { BooleanSettingOptions } from '../ComponentSettingsConstants';
import { Select } from '@okta/odyssey-react-mui';

const BooleanSetting = ({
  name,
  instanceValue,
  defaultValue,
  readOnly = false,
  onChange,
}) => {
  return (
    <Select
      id="settings"
      key={name}
      name={'settings.' + name}
      value={instanceValue !== undefined ? instanceValue : null}
      onChange={onChange}
      options={BooleanSettingOptions}
      placeholder={
        (defaultValue !== undefined ? defaultValue.toString() : '') +
        ' (default)'
      }
      isReadOnly={readOnly}
      isDisabled={readOnly}
      selection
      fluid
      inline
    />
  );
};

export default BooleanSetting;
