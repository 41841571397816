import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@okta/odyssey-react-mui';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './components/Themes';
import { useTheme } from './components/ThemeController';
import DemoDetails from './routes/DemoDetails';
import ApplicationDetails from './routes/ApplicationDetails';
import AppInstanceDetails from './routes/AppInstanceDetails';
import CreateApplication from './routes/CreateApplication';
import CreateDemo from './routes/CreateDemo';
import DemoApps from './routes/DemoApps';
import DemoResources from './routes/DemoComponents';
import StatusBanner from './components/StatusBanner';
import ResourceDetails from './routes/ResourceDetails';
import ResourceInstanceDetails from './routes/ResourceInstanceDetails';
import CreateResource from './routes/CreateResource';
import flagsmith from 'flagsmith';
import DemoContextProvider from './DemoContext';
import OpportunityContextProvider from './OpportunityContext';
import LibraryDemonstrationCreate from './routes/LibraryDemonstrationCreate';
import Settings from './routes/Settings';
import TemplateDeploy from './routes/TemplateDeploy';
import UserPreferenceContextProvider from './UserPreferenceContext';
import Heropa from './components/Heropa';
import {
  Button,
  OdysseyThemeProvider,
  Typography,
} from '@okta/odyssey-react-mui';
import { SideNav, TopNav, UserProfile } from '@okta/odyssey-react-mui/labs';
import {
  AddCircleIcon,
  UserIcon,
  ChatIcon,
  ServerIcon,
  SettingsIcon,
} from '@okta/odyssey-react-mui/icons';
import { Code } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { Link } from '@okta/odyssey-react-mui';
import Applications from './components/Applications';
import Resources from './components/Resources';
import Demos from './components/Demos';
import DemoComponents from './routes/DemoComponents';

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect, user } =
    useAuth0();
  const navigate = useNavigate();

  const [theme] = useTheme();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  // Temporary workaround to circumvent page refresh issue of sidenav. To be reverted when fix is released by Odyssey.
  function handleNavigation(e) {
    if (!e.target) return;

    let textToCheck = e.target.innerText;
    if (
      e.target.children &&
      e.target.children.length > 0 &&
      e.target.children[0] &&
      !textToCheck
    ) {
      textToCheck = e.target.children[0].innerText;
    }
    switch (textToCheck) {
      case 'Applications':
        navigate('/apps');
        break;
      case 'Resources':
        navigate('/resources');
        break;
      case 'Demo Environments':
        navigate('/');
        break;
      case 'Create New Environment':
        navigate('/demo');
        break;
      default:
        break;
    }
  }

  if (isLoading) {
    return (
      <div className="loadingContainer">
        <CircularProgress color="inherit" label="Loading" />
        <span>Loading</span>
      </div>
    );
  }
  if (error) {
    window.location.replace(
      `${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENT_ID}&error=${error.name}&error_description=${error.message}`
    );
    return null;
  }
  if (isAuthenticated) {
    flagsmith.identify(user.email, {
      connection:
        user['https://' + process.env.REACT_APP_DOMAIN + '/connection/'],
    });
    return (
      <ThemeProvider theme={themeMode}>
        <OdysseyThemeProvider>
          <UserPreferenceContextProvider>
            <OpportunityContextProvider>
              <DemoContextProvider>
                <div
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onClick={handleNavigation}
                >
                  <SideNav
                    appName="Demo Platform"
                    sideNavItems={[
                      {
                        endIcon: <AddCircleIcon />,
                        id: 'AddNewFolder',
                        label: 'Create New Environment',
                        //  href: '/demo',
                        // onClick: (event) => {
                        //   event.preventDefault();
                        //   navigate('/demo')();
                        // },
                      },
                      {
                        id: 'item1-1',
                        label: 'Demo Environments',
                        startIcon: <ServerIcon />,
                        // href: '/',
                      },
                      {
                        id: 'build',
                        label: 'Build',
                        startIcon: <Code />,
                        nestedNavItems: [
                          {
                            // href: '/apps',
                            id: 'item2-1',
                            label: 'Applications',
                          },
                          {
                            // href: '/resources',
                            id: 'item2-2',
                            label: 'Resources',
                          },
                          {
                            target: '_blank',
                            href: 'https://docs.demo.okta.com/',
                            id: 'item2-3',
                            label: 'API Docs',
                          },
                        ],
                      },
                      {
                        id: 'item3-1',
                        startIcon: <ChatIcon />,
                        label: 'Support',
                        nestedNavItems: [
                          {
                            target: '_blank',
                            href: 'https://okta.slack.com/archives/CE676UAUS',
                            id: 'item3-2',
                            label: 'Slack',
                          },
                          {
                            target: '_blank',
                            href: 'https://form.asana.com/?k=puvfnI_Q7cfzv9zrbAPA-A&d=10114792544943',
                            id: 'item3-3',
                            label: 'Support form',
                          },
                        ],
                      },
                    ]}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: '1',
                      backgroundColor: 'rgb(244, 244, 244)',
                      minHeight: '100vh',
                    }}
                  >
                    <TopNav
                      rightSideComponent={
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Button
                            onClick={() => navigate('/settings')}
                            variant="floating"
                            startIcon={<SettingsIcon />}
                          />
                          <UserProfile
                            profileIcon={<UserIcon />}
                            userName={user.name}
                            orgName={flagsmith
                              .getTrait('connection')
                              ?.toUpperCase()}
                          />
                        </div>
                      }
                    ></TopNav>
                    <Container
                      sx={{
                        display: 'flex',
                        flexGrow: '1',
                        flexDirection: 'column',
                      }}
                    >
                      <StatusBanner />
                      <Routes>
                        <Route path="/" exact element={<Demos />} />
                        <Route path="/settings" exact element={<Settings />} />
                        <Route path="/demo" exact element={<CreateDemo />} />
                        <Route
                          path="/demo/:demoName"
                          element={<DemoDetails />}
                        />
                        <Route
                          path="/demo/:demoName/apps/:appId"
                          element={<AppInstanceDetails />}
                        />
                        <Route
                          path="/demo/:demoName/components"
                          element={<DemoComponents />}
                        />
                        <Route
                          path="/demo/:demoName/resources/:resourceId"
                          element={<ResourceInstanceDetails />}
                        />
                        <Route path="/apps" exact element={<Applications />} />
                        <Route
                          path="/app"
                          exact
                          element={<CreateApplication />}
                        />
                        <Route
                          path="/app/:appId"
                          element={<ApplicationDetails />}
                        />
                        <Route
                          path="/resources"
                          exact
                          element={<Resources />}
                        />
                        <Route
                          path="/resource"
                          exact
                          element={<CreateResource />}
                        />
                        <Route
                          path="/resource/:resourceId"
                          element={<ResourceDetails />}
                        />
                        <Route
                          path="/library/:appId/create"
                          element={<LibraryDemonstrationCreate />}
                        />
                        <Route path="/template" element={<TemplateDeploy />} />
                        <Route
                          path="/template/:template"
                          element={<TemplateDeploy />}
                        />
                        <Route path="/vm" exact element={<Heropa />} />
                      </Routes>
                    </Container>
                    <Container
                      sx={{
                        display: 'flex',
                        alignItems: 'center !important',
                        marginTop: '1rem',
                      }}
                    >
                      <Box display="flex" gap={'2rem'} className="footer-links">
                        <Typography variant="support">
                          Copyright © 2024 Okta. All rights reserved.
                        </Typography>
                        <Link
                          variant="monochrome"
                          href="https://www.okta.com/privacy-policy/"
                        >
                          Privacy Policy
                        </Link>
                        <Link
                          variant="monochrome"
                          href="https://www.okta.com/terms-of-service/"
                        >
                          Site Terms
                        </Link>
                        <button className="ot-sdk-show-settings MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-dr9k8r-MuiTypography-root-MuiLink-root buttonLink">
                          Cookie Settings
                        </button>
                        <Link
                          variant="monochrome"
                          href="https://www.okta.com/your-privacy-choices/"
                        >
                          Your Privacy Choices
                        </Link>
                      </Box>
                    </Container>
                  </div>
                </div>
              </DemoContextProvider>
            </OpportunityContextProvider>
          </UserPreferenceContextProvider>
        </OdysseyThemeProvider>
      </ThemeProvider>
    );
  } else {
    return loginWithRedirect({ appState: { targetUrl: window.location } });
  }
}

export default App;
