import React from 'react';
import PropTypes from 'prop-types';
import './Divider.css';

const Divider = ({
  className,
  horizontal,
  vertical,
  section,
  clearing,
  hidden,
  children,
  style,
}) => {
  const dividerClass = `
    custom-divider
    ${horizontal ? 'custom-divider--horizontal' : ''}
    ${vertical ? 'custom-divider--vertical' : ''}
    ${section ? 'custom-divider--section' : ''}
    ${clearing ? 'custom-divider--clearing' : ''}
    ${hidden ? 'custom-divider--hidden' : ''}
    ${children ? 'custom-divider--content' : ''}
    ${className || ''}
  `.trim();

  return (
    <div className={dividerClass} style={style}>
      {children}
    </div>
  );
};

Divider.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  section: PropTypes.bool,
  clearing: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
};

Divider.defaultProps = {
  className: '',
  horizontal: false,
  vertical: false,
  section: false,
  clearing: false,
  hidden: false,
  children: null,
  style: {},
};

export default Divider;
