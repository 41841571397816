import { GroupIcon, UserIcon } from '@okta/odyssey-react-mui/icons';

const CategoryLabel = ({ category }) => {
  switch (category) {
    case 'private':
      return (
        <span>
          <UserIcon /> Private
        </span>
      );
    case 'community':
      return (
        <span>
          <GroupIcon />
          Community
        </span>
      );
    case 'managed':
      return (
        <span>
          <GroupIcon />
          Managed
        </span>
      );
    default:
      break;
  }
};
export default CategoryLabel;
