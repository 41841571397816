import React from 'react';
import './MetaData.css';

const MetaData = ({ meta_data }) => {
  var created_on = new Date(meta_data.created_on);
  var modified_on = new Date(meta_data.last_modified_on);

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return (
    <div className="metaDataTable">
      <div className="metaDataRow">
        <div className="metaDataCell headCell">Created By</div>
        <div className="metaDataCell headCell">Created</div>
        <div className="metaDataCell headCell">Updated By</div>
        <div className="metaDataCell headCell">Updated</div>
      </div>
      <div className="metaDataRow">
        <div className="metaDataCell" title={meta_data.created_by}>
          {meta_data.created_by}
        </div>
        <div
          className="metaDataCell"
          title={new Intl.DateTimeFormat(undefined, dateOptions).format(
            created_on
          )}
        >
          {new Intl.DateTimeFormat(undefined, dateOptions).format(created_on)}
        </div>
        <div className="metaDataCell" title={meta_data.last_modified_by}>
          {meta_data.last_modified_by}
        </div>
        <div
          className="metaDataCell"
          title={new Intl.DateTimeFormat(undefined, dateOptions).format(
            modified_on
          )}
        >
          {new Intl.DateTimeFormat(undefined, dateOptions).format(modified_on)}
        </div>
      </div>
    </div>
  );
};
export default MetaData;
