import React from 'react';
import PropTypes from 'prop-types';
import './Container.css';

const Container = ({ children, className, textAlign, fluid, style }) => {
  const containerClass = `
    custom-container
    ${fluid ? 'custom-container--fluid' : ''}
    ${textAlign ? `custom-container--${textAlign}` : ''}
    ${className || ''}
  `.trim();

  return (
    <div className={containerClass} style={style}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  fluid: PropTypes.bool,
  style: PropTypes.object,
};

Container.defaultProps = {
  className: '',
  textAlign: 'left',
  fluid: false,
  style: {},
};

export default Container;
