import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from '../Config';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import { useFlags } from 'flagsmith/react';
import DemoComponentCard from '../components/DemoComponentCard';
import {
  attachAppToDemonstration,
  getIDP,
  listApplications,
  listResources,
} from '../services/DemoAPI';
import { BreadcrumbList, Button, Surface } from '@okta/odyssey-react-mui';
import Header from '../components/ui/Header/Header';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { DataView } from '@okta/odyssey-react-mui/labs';
import Image from '../components/ui/Image/Image';
import './DemoComponents.css';
const DemoComponents = () => {
  let params = useParams();
  const navigate = useNavigate();

  const [demo, setDemo] = useState();
  const [idp, setIdp] = useState();
  const [components, setComponents] = useState();
  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['community_access']);
  const columns = [{ field: 'category', headerName: 'Category', width: 100 }];
  const getDemo = useCallback(async () => {
    setError();
    if (isAuthenticated) {
      axios
        .get(
          Config.resourceServer.demoAPI + '/demonstration/' + params.demoName,
          {
            headers: {
              Authorization: 'Bearer ' + (await getAccessTokenSilently()),
            },
          }
        )
        .then((response) => {
          setDemo(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setDemo();
    }
  }, [getAccessTokenSilently, isAuthenticated, setDemo, params.demoName]);

  useEffect(() => {
    async function getIDPDetails() {
      getIDP(await getAccessTokenSilently(), demo.idp_id)
        .then((response) => {
          setIdp(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    }
    if (demo) {
      getIDPDetails();
    }
  }, [demo, getAccessTokenSilently]);
  const getApps = async () => {
    // if (isAuthenticated && demo && idp) {
    let applicationData = await listApplications(
      await getAccessTokenSilently(),
      idp.type
    );

    var availableApps = applicationData.data;

    for (
      let instanceIndex = 0;
      instanceIndex < demo.app_instances.length;
      instanceIndex++
    ) {
      const element = demo.app_instances[instanceIndex];
      let index = availableApps.findIndex(
        (x) => x.application_id === element.applicationId
      );
      if (index !== -1) {
        availableApps.splice(index, 1);
      }
    }
    return availableApps;
  };
  const getComponents = useCallback(async () => {
    if (isAuthenticated && demo && idp) {
      let resourceData = await listResources(
        await getAccessTokenSilently(),
        idp.type
      );
      for (
        let instanceIndex = 0;
        instanceIndex < demo.resource_instances.length;
        instanceIndex++
      ) {
        const element = demo.resource_instances[instanceIndex];
        let index = resourceData.data.findIndex(
          (x) => x.resource_id === element.resourceId
        );
        if (index !== -1) {
          resourceData.data.splice(index, 1);
        }
      }
      let availableApps = await getApps();
      resourceData.data.forEach((resource) => {
        resource['componentType'] = 'resource';
      });
      availableApps.forEach((application) => {
        application['componentType'] = 'application';
      });
      let combinedData = [...resourceData.data, ...availableApps];
      setComponents(combinedData);
    } else {
      setComponents();
    }
  }, [
    demo,
    getAccessTokenSilently,
    isAuthenticated,
    flags.community_access,
    idp,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      getDemo();
    } else {
      setDemo();
    }
  }, [getDemo, isAuthenticated, getAccessTokenSilently, setDemo]);

  useEffect(() => {
    getComponents();
  }, [demo, getComponents]);

  async function attachResource(id, settings) {
    setWaiting(true);
    var data = JSON.stringify({
      resourceId: id,
      settings: settings,
    });

    var config = {
      method: 'post',
      url:
        Config.resourceServer.demoAPI +
        '/demonstration/' +
        params.demoName +
        '/resources',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getAccessTokenSilently()),
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        navigate('/demo/' + params.demoName);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }
  async function attachApp(id) {
    setWaiting(true);
    attachAppToDemonstration(
      await getAccessTokenSilently(),
      params.demoName,
      id
    )
      .then((response) => {
        navigate('/demo/' + params.demoName);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  const dataViewGetData = useCallback(
    ({ search, filters }) => {
      let filteredData = structuredClone(components);
      if (search && search.length > 0) {
        const lowerCaseSearch = search.toLowerCase();

        filteredData = filteredData.filter((row) =>
          Object.values(row).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(lowerCaseSearch);
            }
            return false;
          })
        );
      }
      function filterData(data, filters) {
        return data.filter((item) => {
          return filters.every((filter) => {
            if (!filter.value || filter.value.length === 0) {
              return true;
            }
            const selectedValues = filter.value.map((v) => v.value);
            const itemValue = item[filter.id];
            return selectedValues.includes(itemValue);
          });
        });
      }
      if (filters && filteredData.length) {
        return filterData(filteredData, filters).sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      return filteredData.sort((a, b) => a.name.localeCompare(b.name));
    },
    [components]
  );

  return (
    <Surface className="appComponent">
      <BreadcrumbList>
        <NavigationButton destination={`/`} msg="Demos" />
        <NavigationButton
          destination={`/demo/${params.demoName}`}
          msg={params.demoName}
        />
        <i href="">Demo Components Library</i>
      </BreadcrumbList>
      <Header className="contentHeader">
        Demo Components Library{' '}
        <Header.Subheader>Add Components to {params.demoName}</Header.Subheader>
      </Header>
      {error ? (
        <ErrorMessage error={error} retryAction={getComponents} />
      ) : null}

      {demo && idp && components ? (
        <DataView
          getData={dataViewGetData}
          availableLayouts={['grid']}
          hasSearch
          hasFilters
          filters={[
            {
              id: 'category',
              label: 'Category filter',
              variant: 'multi-select',
              options: [
                {
                  label: 'Private',
                  value: 'private',
                },
                {
                  label: 'Managed',
                  value: 'managed',
                },
                {
                  label: 'Community',
                  value: 'community',
                },
              ],
            },
            {
              id: 'componentType',
              label: 'Component Type',
              variant: 'multi-select',
              options: [
                {
                  label: 'Application',
                  value: 'application',
                },
                {
                  label: 'Resource',
                  value: 'resource',
                },
              ],
            },
          ]}
          cardLayoutOptions={{
            itemProps: (row) => ({
              maxGridColumns: 4,
              overline: `${row.category}`,
              title: (
                <>
                  {row.name}
                  {row.logo && (
                    <Image
                      floated="right"
                      size="tiny"
                      src={row.logo}
                      style={{ float: 'right', margin: '10px' }}
                    />
                  )}
                </>
              ),
              children: (
                <DemoComponentCard
                  component={row}
                  waiting={waiting}
                  attachEvent={
                    row.componentType === 'resource'
                      ? attachResource
                      : attachApp
                  }
                />
              ),
            }),
          }}
        />
      ) : (
        !error && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={45} height={40} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={400} height={40} />
              </Stack>
            </div>
            <div className="skeletonContainer">
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={330} height={250} />
              </Stack>
            </div>
          </div>
        )
      )}
    </Surface>
  );
};
export default DemoComponents;
