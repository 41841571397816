import {
  FolderIcon,
  ServerIcon,
  UserIcon,
} from '@okta/odyssey-react-mui/icons';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SchoolIcon from '@mui/icons-material/School';
import './DemoTypeLabel.css';
const DemoTypeLabel = ({ type }) => {
  switch (type) {
    case 'opportunity':
      return (
        <span className="demoTypeLabel">
          <MonetizationOnIcon /> Opportunity
        </span>
      );
    case 'enablement':
      return (
        <span className="demoTypeLabel">
          <SchoolIcon /> Enablement
        </span>
      );
    case 'lab':
      return (
        <span className="demoTypeLabel">
          <ServerIcon /> Lab
        </span>
      );
    default:
      break;
  }
};
export default DemoTypeLabel;
