import React from 'react';
import PropTypes from 'prop-types';
import './Header.css';
import { Typography } from '@okta/odyssey-react-mui';
const Header = ({ children, subheader, className, style, as = 'h1' }) => {
  return (
    <div className={`custom-header ${className}`} style={style}>
      <Typography variant={as}>{children}</Typography>
      {subheader && <Typography variant="h2">{subheader}</Typography>}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

Header.defaultProps = {
  subheader: null,
  className: '',
  style: {},
};
Header.displayName = 'Header';

Header.Subheader = ({ children, className, style }) => (
  <h2 className={`custom-header-subheader ${className}`} style={style}>
    {children}
  </h2>
);

Header.Subheader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Header.Subheader.defaultProps = {
  className: '',
  style: {},
};
Header.Subheader.displayName = 'Header.Subheader';
export default Header;
