import React, { useState } from 'react';
import { useFlags } from 'flagsmith/react';
import NavigationButton from '../components/NavigationButton';
import flagsmith from 'flagsmith';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../Config';
import AWSRegionSetting from '../components/settings/AWSRegionSetting';
import RDPClientPreference from '../components/settings/RDPClientPreference';
import {
  Surface,
  Button,
  Checkbox,
  TextField,
  Form,
  Fieldset,
} from '@okta/odyssey-react-mui';
import Container from '../components/ui/Container/Container';
import Divider from '../components/ui/Divider/Divider';
import Header from '../components/ui/Header/Header';
import './Settings.css';
const Settings = () => {
  const flags = useFlags([
    'aws_linking',
    'demonstration_limits',
    'app_limits',
    'resource_limits',
    'one_click_guide_page',
  ]);
  const { user } = useAuth0();
  const [awsAccountLinked, setAWSAccountLinked] = useState(
    flagsmith.getTrait('aws_account_id')
  );
  const [awsAccountId, setAwsAccountId] = useState(
    flagsmith.getTrait('aws_account_id')
  );
  const [awsPreferredRegion, setAwsPreferredRegion] = useState(
    flagsmith.getTrait('aws_preferred_region')
  );
  const [rdpPreference, setRdpPreference] = useState(
    flagsmith.getTrait('rdp_preference')
  );

  function handleChange(event) {
    if (event.target.name === 'awsAccountId') {
      setAwsAccountId(event.target.value);
    }
  }

  return (
    <Surface className="appComponent">
      <NavigationButton destination="/" />

      <Header className="contentHeader">Settings</Header>

      <Container>
        <Header as="h3">Your Account</Header>

        <div className="settingContainer">
          <div className="settingsRow">
            <div className="cell">
              <b>Email</b>
            </div>
            <div className="cell">{user.email}</div>
          </div>
          <div className="settingsRow">
            <div className="cell">
              <b>Account Type</b>
            </div>
            <div className="cell">{flagsmith.getTrait('connection')}</div>
          </div>
          {flagsmith.getTrait('connection') !== 'guest' && (
            <div className="settingsRow">
              <div className="cell">
                <b>Enablement Limit</b>
              </div>
              <div className="cell">{flags.demonstration_limits.value}</div>
            </div>
          )}
          <div className="settingsRow">
            <div className="cell">
              <b>Application Limit</b>
            </div>
            <div className="cell">{flags.app_limits.value}</div>
          </div>
          <div className="settingsRow">
            <div className="cell">
              <b>Resource Limit</b>
            </div>
            <div className="cell">{flags.resource_limits.value}</div>
          </div>
        </div>
      </Container>

      <Container>
        <Divider />
        <Header as="h3">Preferences</Header>
        <div className="settingContainer">
          <div className="settingsRow">
            <div className="cell">
              <b>RDP Client</b>
            </div>
            <div className="cell">
              <RDPClientPreference
                name="rdp_Preference"
                instanceValue={rdpPreference}
                defaultValue="Microsoft"
                onChange={(event) => {
                  const { name, value } = event.target;
                  flagsmith.setTrait('rdp_preference', value);
                  setRdpPreference(value);
                }}
              />
            </div>
          </div>
        </div>
      </Container>

      {flags.aws_linking.enabled && (
        <Container>
          <Divider />

          {awsAccountLinked !== undefined ? (
            <Container>
              <div className="settingContainer">
                <div className="settingsRow">
                  <div className="cell">
                    <b>Default AWS Account ID</b>
                  </div>
                  <div>{awsAccountId}</div>
                  <div className="cell">
                    <Button
                      onClick={() => {
                        flagsmith.setTrait('aws_account_id', null);
                        setAWSAccountLinked(undefined);
                      }}
                      label="Reset"
                    >
                      Reset
                    </Button>
                  </div>
                </div>
                <div className="settingsRow">
                  <div className="cell">
                    <b>Default Region</b>
                  </div>
                  <div className="cell">
                    <AWSRegionSetting
                      name="awsRegion"
                      instanceValue={awsPreferredRegion}
                      defaultValue="us-east-1"
                      onChange={(event) => {
                        const { name, value } = event.target;
                        flagsmith.setTrait('aws_preferred_region', value);
                        setAwsPreferredRegion(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <Surface>
                <p>
                  To complete the account linking process run the CloudFormation
                  script below to the role required for demo.okta to deploy
                  components in your AWS.
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={config.features.awsCrossAccountLink.replace(
                    '<region>',
                    awsPreferredRegion
                  )}
                >
                  <Button
                    icon="external alternate"
                    label="Perform Linking in AWS"
                  />
                </a>
              </Surface>
            </Container>
          ) : (
            <Form
              noValidate
              formActions={
                <>
                  <Button
                    type="submit"
                    onClick={() => {
                      flagsmith.setTrait('aws_account_id', awsAccountId);
                      setAWSAccountLinked(awsAccountId);
                    }}
                    label="Save"
                  >
                    Save
                  </Button>
                </>
              }
            >
              <Fieldset required>
                <TextField
                  pattern="^\d{12}$"
                  label="AWS Account ID"
                  placeholder="12 digit AWS account id"
                  name="awsAccountId"
                  required
                  value={awsAccountId}
                  onChange={handleChange}
                ></TextField>
              </Fieldset>
            </Form>
          )}
        </Container>
      )}
    </Surface>
  );
};
export default Settings;
