import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import CategoryLabel from '../components/CategoryLabel';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import MetaData from '../components/MetaData';
import ComponentSettings from '../components/ComponentSettings';
import {
  deleteApplication,
  getApplication,
  updateApplication,
} from '../services/DemoAPI';
import Dependencies from '../components/Dependencies';
import { DemonstrationDiscoveryOptions } from '../components/DemonstrationDiscoveryConstants';
import CollaborationModal from '../components/collaboration/CollaborationModal';
import CollaboratorList from '../components/collaboration/CollaboratorList';
import { useFlags } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import Container from '../components/ui/Container/Container';
import Header from '../components/ui/Header/Header';
import Divider from '../components/ui/Divider/Divider';
import {
  CircularProgress,
  Surface,
  Button,
  Fieldset,
  TextField,
  Form,
  Select,
} from '@okta/odyssey-react-mui';
import { CopyIcon, DeleteIcon, EditIcon } from '@okta/odyssey-react-mui/icons';
import FormSkeleton from '../components/ui/FormSkeleton/FormSkeleton';
const ApplicationDetails = () => {
  const navigate = useNavigate();

  let params = useParams();
  const [application, setApplication] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState();
  const [baseAddress, setBaseAddress] = useState('');
  const [baseAddressError, setBaseAddressError] = useState();
  const [description, setDescription] = useState('');
  const [multiTenancy, setMultiTenancy] = useState(true);
  const [dependencies, setDependencies] = useState();
  const [requestHook, setRequestHook] = useState();
  const [requestHookError, setRequestHookError] = useState();
  const [createHook, setCreateHook] = useState();
  const [createHookError, setCreateHookError] = useState();
  const [updateHook, setUpdateHook] = useState();
  const [updateHookError, setUpdateHookError] = useState();
  const [destroyHook, setDestroyHook] = useState();
  const [destroyHookError, setDestroyHookError] = useState();
  const [settings, setSettings] = useState({});
  const [documentationLink, setDocumentationLink] = useState();
  const [documentationLinkError, setDocumentationLinkError] = useState();
  const [supportLink, setSupportLink] = useState();
  const [supportLinkError, setSupportLinkError] = useState();
  const [logoLink, setLogoLink] = useState();
  const [logoLinkError, setLogoLinkError] = useState();
  const [videoLink, setVideoLink] = useState();
  const [videoLinkError, setVideoLinkError] = useState();
  const [tags, setTags] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const flags = useFlags(['collaboration']);

  const getApp = useCallback(async () => {
    getApplication(await getAccessTokenSilently(), params.appId)
      .then((response) => {
        setApplication(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [setApplication, getAccessTokenSilently, params.appId]);

  const initalizeFields = useCallback(async () => {
    if (application) {
      setName(application.name);
      setBaseAddress(application.baseAddress);
      setMultiTenancy(application.multiTenancy);
      setDescription(application.description);
      if (application.dependsOn) {
        setDependencies(application.dependsOn);
      }
      if (application.settings) {
        setSettings(application.settings);
      }
      if (application.hooks && application.hooks.request) {
        setRequestHook(application.hooks.request);
      }
      if (application.hooks && application.hooks.create) {
        setCreateHook(application.hooks.create);
      }
      if (application.hooks && application.hooks.update) {
        setUpdateHook(application.hooks.update);
      }
      if (application.hooks && application.hooks.destroy) {
        setDestroyHook(application.hooks.destroy);
      }
      if (application.documentationLink) {
        setDocumentationLink(application.documentationLink);
      }
      if (application.supportLink) {
        setSupportLink(application.supportLink);
      }
      if (application.logo) {
        setLogoLink(application.logo);
      }
      if (application.video) {
        setVideoLink(application.video);
      }
      if (application.tags) {
        setTags(application.tags);
      }
    }
  }, [application]);

  async function updateApp() {
    if (validateForm()) {
      setWaiting(true);
      updateApplication(
        await getAccessTokenSilently(),
        application.application_id,
        name,
        baseAddress,
        multiTenancy,
        description,
        {
          request: requestHook,
          create: createHook,
          update: updateHook,
          destroy: destroyHook,
        },
        settings,
        dependencies,
        documentationLink,
        supportLink,
        videoLink,
        logoLink,
        tags
      )
        .then((response) => {
          setApplication(response.data);
          setEditMode(false);
          setWaiting(false);
        })
        .catch((error) => {
          setError(error);
          setEditMode(false);
          setWaiting(false);
        });
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getApp();
    } else {
      setApplication();
    }
  }, [getApp, isAuthenticated, getAccessTokenSilently, setApplication]);

  useEffect(() => {
    initalizeFields();
  }, [initalizeFields, application, editMode]);

  function handleChange(event) {
    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'baseAddress':
        setBaseAddress(event.target.value);
        break;
      //mutlitenancy is skipped here purposefully as it should not be updated
      case 'description':
        setDescription(event.target.value);
        break;
      case 'documentationLink':
        setDocumentationLink(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'videoLink':
        setVideoLink(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'logoLink':
        setLogoLink(event.target.value === '' ? undefined : event.target.value);
        break;
      case 'supportLink':
        setSupportLink(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'requestHook':
        event.target.value === ''
          ? setRequestHook(undefined)
          : setRequestHook(event.target.value);
        break;
      case 'createHook':
        event.target.value === ''
          ? setCreateHook(undefined)
          : setCreateHook(event.target.value);
        break;
      case 'updateHook':
        event.target.value === ''
          ? setUpdateHook(undefined)
          : setUpdateHook(event.target.value);
        break;
      case 'destroyHook':
        event.target.value === ''
          ? setDestroyHook(undefined)
          : setDestroyHook(event.target.value);
        break;
      default:
        break;
    }
  }

  function validateForm() {
    const validURlregex =
      '((https?)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    var result = true;
    if (name === '') {
      setNameError('Please enter a name for your application.');
      result = false;
    } else {
      setNameError();
    }
    if (logoLink && !logoLink.match(validURlregex)) {
      setLogoLinkError('Please enter a valid URL to your logo');
      result = false;
    } else {
      setLogoLinkError();
    }
    if (documentationLink && !documentationLink.match(validURlregex)) {
      setDocumentationLinkError(
        'Please enter a valid URL to your documentation'
      );
      result = false;
    } else {
      setDocumentationLinkError();
    }
    if (videoLink && !videoLink.match(validURlregex)) {
      setVideoLinkError('Please enter a valid URL to your video');
      result = false;
    } else {
      setVideoLinkError();
    }
    if (supportLink && !supportLink.match(validURlregex)) {
      setSupportLinkError('Please enter a valid URL to your documentation');
      result = false;
    } else {
      setSupportLinkError();
    }
    if (!baseAddress.match(validURlregex)) {
      setBaseAddressError('Please enter the full URL of your application.');
      result = false;
    } else {
      setBaseAddressError();
    }
    if (requestHook && !requestHook.match(validURlregex)) {
      setRequestHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setRequestHookError();
    }
    if (createHook && !createHook.match(validURlregex)) {
      setCreateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setCreateHookError();
    }
    if (updateHook && !updateHook.match(validURlregex)) {
      setUpdateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setUpdateHookError();
    }
    if (destroyHook && !destroyHook.match(validURlregex)) {
      setDestroyHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setDestroyHookError();
    }
    return result;
  }

  async function requestDeleteApplication() {
    setWaiting(true);
    deleteApplication(
      await getAccessTokenSilently(),
      application.application_id
    )
      .then((response) => {
        navigate('/');
        setWaiting(false);
      })
      .catch((error) => {
        setError(error);
        setWaiting(false);
      });
  }

  return (
    <Surface className="appComponent">
      <NavigationButton destination="/apps" />
      {application ? (
        <Container>
          <div className="componentHeader">
            <div>
              <Header className="contentHeader">
                Application: {application.name}
              </Header>
              <CollaboratorList
                entity={application}
                setOpen={setOpen}
                includeManage={flags.collaboration?.enabled}
              />
              <CollaborationModal
                entity={application}
                open={open}
                setOpen={setOpen}
                entityType="application"
                function_reloaddata={getApp}
              ></CollaborationModal>
            </div>
            <div>
              {editMode ? (
                <span>
                  <Button
                    floated="right"
                    isDisabled={waiting}
                    className="branded"
                    type="submit"
                    label="Update"
                    onClick={() => updateApp()}
                  >
                    Update
                  </Button>
                  <Button
                    floated="right"
                    isDisabled={waiting}
                    className="branded basic"
                    type="reset"
                    variant="secondary"
                    label="Cancel"
                    onClick={() => {
                      setEditMode(false);
                      getApp();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
              ) : (
                <span>
                  <Button
                    floated="right"
                    isDisabled={waiting}
                    startIcon={<EditIcon />}
                    label="Edit"
                    className="branded"
                    type="button"
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    floated="right"
                    isDisabled={waiting}
                    type="button"
                    color="red"
                    variant="danger"
                    startIcon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => {
                      requestDeleteApplication();
                    }}
                  >
                    Delete
                  </Button>
                </span>
              )}
            </div>
          </div>

          <MetaData meta_data={application.meta_data} />

          <Divider />
        </Container>
      ) : (
        <Header className="contentHeader">Application:</Header>
      )}
      {error ? <ErrorMessage error={error} /> : null}

      <Container>
        {application ? (
          <Container>
            <Form loading={waiting}>
              <Fieldset>
                <div style={{ marginBottom: '10px' }}>
                  <div>Category</div>
                  <CategoryLabel category={application.category} />
                  {flagsmith.getTrait('connection') === 'employee' &&
                    application.category === 'private' && (
                      <a
                        className="padded"
                        href="https://form.asana.com/?k=PT8Qfq-ZNf9n-H9CuXrRFA&d=10114792544943"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Share to Community
                      </a>
                    )}
                </div>

                <TextField
                  id="componentID"
                  label="Component ID"
                  value={application.application_id}
                  isDisabled
                />
                <TextField
                  id="name"
                  label="Name"
                  value={name || ''}
                  onChange={handleChange}
                  errorMessage={nameError}
                  onBlur={validateForm}
                  maxLength="30"
                  isDisabled={!editMode}
                />
                <TextField
                  id="description"
                  value={description || ''}
                  onChange={handleChange}
                  label="Description"
                  isMultiline
                  rows="3"
                  maxLength="1024"
                  placeholder="A custom demo."
                  isDisabled={!editMode}
                />
                <TextField
                  id="logoLink"
                  value={logoLink || ''}
                  onChange={handleChange}
                  errorMessage={logoLinkError}
                  onBlur={validateForm}
                  label="Logo Link"
                  maxLength="1024"
                  isDisabled={!editMode}
                />
                <TextField
                  id="documentationLink"
                  value={documentationLink || ''}
                  onChange={handleChange}
                  errorMessage={documentationLinkError}
                  onBlur={validateForm}
                  label="Documentation Link"
                  maxLength="1024"
                  isDisabled={!editMode}
                />
                <TextField
                  id="videoLink"
                  value={videoLink || ''}
                  onChange={handleChange}
                  errorMessage={videoLinkError}
                  onBlur={validateForm}
                  label="Video Link"
                  maxLength="1024"
                  isDisabled={!editMode}
                />
                <TextField
                  id="supportLink"
                  value={supportLink || ''}
                  onChange={handleChange}
                  errorMessage={supportLinkError}
                  onBlur={validateForm}
                  label="Support Link"
                  maxLength="1024"
                  isDisabled={!editMode}
                />

                <Header as="h3">Application Configuration</Header>

                <TextField
                  id="baseAddress"
                  label="Base Address"
                  value={baseAddress || ''}
                  onChange={handleChange}
                  placeholder="https://example.com"
                  errorMessage={baseAddressError}
                  onBlur={validateForm}
                  isDisabled={!editMode}
                />

                <Select
                  label="Demonstration discovery method"
                  name="multiTenancy"
                  options={DemonstrationDiscoveryOptions}
                  value={multiTenancy}
                  selection
                  isDisabled
                />

                {dependencies && (
                  <Dependencies
                    dependencies={dependencies}
                    onChange={setDependencies}
                    allowEdit={editMode}
                  />
                )}

                <Header as="h3">Settings</Header>
                <ComponentSettings
                  settings={settings}
                  allowEdit={editMode}
                  setSettings={setSettings}
                />

                <Header as="h3">Lifecycle Hooks</Header>
                <TextField
                  id="requestHook"
                  value={requestHook || ''}
                  onChange={handleChange}
                  label="Requested Event"
                  placeholder="https://example.com/requested"
                  errorMessage={requestHookError}
                  onBlur={validateForm}
                  isDisabled={!editMode}
                />
                <TextField
                  id="createHook"
                  value={createHook || ''}
                  onChange={handleChange}
                  label="Created Event"
                  placeholder="https://example.com/created"
                  errorMessage={createHookError}
                  onBlur={validateForm}
                  isDisabled={!editMode}
                />
                <TextField
                  id="updateHook"
                  value={updateHook || ''}
                  onChange={handleChange}
                  label="Updated Event"
                  placeholder="https://example.com/updated"
                  errorMessage={updateHookError}
                  onBlur={validateForm}
                  isDisabled={!editMode}
                />
                <TextField
                  id="destroyHook"
                  value={destroyHook || ''}
                  onChange={handleChange}
                  label="Deleted Event"
                  placeholder="https://example.com/deleted"
                  errorMessage={destroyHookError}
                  onBlur={validateForm}
                  isDisabled={!editMode}
                />
              </Fieldset>
            </Form>

            <Divider />

            {application.oidc_configuration ? (
              <Container>
                <Header as="h3">Demo API Credentials</Header>
                {/* {/* <Form> */}
                <Fieldset>
                  <TextField
                    label="Application Id"
                    value={application.application_id}
                    isDisabled
                    endAdornment={
                      <Button
                        variant="floating"
                        isDisabled={waiting}
                        startIcon={<CopyIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            application.application_id
                          );
                        }}
                      />
                    }
                  />
                  <TextField
                    label="Token URL"
                    value={application.oidc_configuration.tokenUrl}
                    isDisabled
                    endAdornment={
                      <Button
                        variant="floating"
                        isDisabled={waiting}
                        startIcon={<CopyIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            application.oidc_configuration.tokenUrl
                          );
                        }}
                      />
                    }
                  />
                  <TextField
                    label="Client Id"
                    value={application.oidc_configuration.client_id}
                    isDisabled
                    endAdornment={
                      <Button
                        variant="floating"
                        isDisabled={waiting}
                        startIcon={<CopyIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            application.oidc_configuration.client_id
                          );
                        }}
                      />
                    }
                  />
                  <TextField
                    label="Client Secret"
                    value={application.oidc_configuration.client_secret}
                    type="password"
                    isDisabled
                    endAdornment={
                      <Button
                        variant="floating"
                        isDisabled={waiting}
                        startIcon={<CopyIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            application.oidc_configuration.client_secret
                          );
                        }}
                      />
                    }
                  />
                </Fieldset>
                {/* </Form> */}
              </Container>
            ) : null}
          </Container>
        ) : (
          !error && <FormSkeleton />
        )}
      </Container>
    </Surface>
  );
};
export default ApplicationDetails;
