import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import flagsmith from 'flagsmith';
import { useFlags } from 'flagsmith/react';
import { useDemoContext } from '../DemoContext';
import { useOpportunityContext } from '../OpportunityContext';
import {
  createIDP,
  createDemonstration,
  linkDemonstrationToOpp,
} from '../services/DemoAPI';
import { generateDemoName } from '../util/GenerateDemoNames';
import {
  Radio,
  Form,
  TextField,
  RadioGroup,
  Callout,
  Fieldset,
  Button,
  Typography,
  BreadcrumbList,
  CircularProgress,
  Surface,
} from '@okta/odyssey-react-mui';
import { Container, Paper } from '@mui/material';
import OpportunitySelect from '../components/fields/OpportunitySelect';
import { SyncIcon } from '@okta/odyssey-react-mui/icons';

const CreateApplication = () => {
  const navigate = useNavigate();
  const demoContext = useDemoContext();

  const [label, setLabel] = useState('');
  const [labelError, setLabelError] = useState();
  const [name, setName] = useState(generateDemoName());
  const [nameError, setNameError] = useState();
  const [type, setType] = useState(
    flagsmith.getTrait('connection') === 'guest' ? 'enablement' : 'opportunity'
  );
  const [idpType, setIDPType] = useState('customer-identity');
  const [idpVariant, setIdpVariant] = useState('production');
  const [idp, setIdp] = useState(null);
  const [opportunity, setOpportunity] = useState(null);
  const [opportunityError, setOpportunityError] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const flags = useFlags([
    'create_workforce_org',
    'create_cis_org',
    'demonstration_limits',
    'opportunity_linking',
    'idp_variants',
    'idp_variant_wic_preview',
    'idp_variant_wic_gcp',
  ]);
  const { demos } = useDemoContext();
  const { opportunities } = useOpportunityContext();

  async function createIdp() {
    setWaiting(true);
    createIDP(await getAccessTokenSilently(), name, idpType, idpVariant)
      .then((response) => {
        setIdp(response.data);
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          setNameError(
            'A demo with that name already exists. Please choose a different name.'
          );
          setWaiting(false);
        } else {
          setError(error);
          setWaiting(false);
        }
      });
  }

  const linkDemoToOpp = useCallback(async () => {
    linkDemonstrationToOpp(
      await getAccessTokenSilently(),
      name,
      opportunity
    ).catch((error) => {
      console.log(error);
    });
  }, [getAccessTokenSilently, name, opportunity]);

  const createDemo = useCallback(async () => {
    if (name && type && idp) {
      setWaiting(true);
      createDemonstration(
        await getAccessTokenSilently(),
        name,
        type,
        idp.idp_id,
        'builder',
        label
      )
        .then((response) => {
          if (
            type === 'opportunity' &&
            flags.opportunity_linking.enabled &&
            opportunities.length > 0 &&
            opportunity !== 'noop'
          ) {
            linkDemoToOpp();
          }
          demoContext.refreshContext();
          navigate('/demo/' + name, {
            replace: true,
            state: { demo: response.data, idp: idp },
          });
        })
        .catch((error) => {
          setError(error);
          setWaiting(false);
        });
    }
  }, [
    getAccessTokenSilently,
    idp,
    name,
    navigate,
    type,
    label,
    flags.opportunity_linking.enabled,
    opportunity,
    linkDemoToOpp,
    opportunities?.length,
    demoContext,
  ]);

  useEffect(() => {
    createDemo();
  }, [idp, createDemo]);

  function submitForm() {
    if (validateForm()) {
      createIdp();
    }
  }

  function validateForm() {
    let result = true;

    //test alphanumeric and hyphen, start & end cannot be hyphen
    const tenantPattern = '^(?!W|-)((?!admin|okta)[a-z-0-9]){3,63}$';
    const tenantRegex = new RegExp(`^${tenantPattern}$`);

    if (
      !name ||
      name.length < 3 ||
      name.length > 63 ||
      !tenantRegex.test(name)
    ) {
      setNameError(
        "Name must be between 3 and 63 characters and include only alphanumeric and hyphen. This must not contain the words 'admin' or 'okta'."
      );
      result = false;
    } else {
      setNameError();
    }
    if (!label) {
      setLabelError("Enter a label, such as 'My CIAM demonstration'");
    }
    if (
      !opportunity &&
      type === 'opportunity' &&
      flags.opportunity_linking.enabled &&
      opportunities.length > 0
    ) {
      setOpportunityError('Please select an opportunity');
      result = false;
    } else {
      setOpportunityError();
    }
    return result;
  }

  function handleChange(event) {
    switch (event.target.name) {
      case 'demoType':
        setType(event.target.value);
        break;
      case 'IDPtype':
        setIDPType(event.target.value);
        setIdpVariant('production');
        break;
      case 'name':
        setName(event.target.value);
        break;
      case 'opportunity':
        setOpportunity(event.target.value);
        setOpportunityError();
        if (event.target.label && (!label || label.length === 0)) {
          setLabel(event.target.label);
        }
        break;
      case 'label':
        setLabel(event.target.value);
        console.log('set label as ' + event.target.value);
        break;
      case 'idpVariant':
        setIdpVariant(event.target.value);
        break;
      default:
        break;
    }
  }

  const demosLimitExcercised =
    flags.demonstration_limits.enabled &&
    demos.filter((demo) => {
      return demo.type === 'enablement';
    }).length >= flags.demonstration_limits.value;

  return (
    <Surface className="appComponent">
      <Container>
        <BreadcrumbList>
          <NavigationButton destination={`/`} msg="Demos" />
          <i href="">Create</i>
        </BreadcrumbList>
        <Typography variant="h1">Create New Demo Environment</Typography>
        {error ? <ErrorMessage error={error} /> : null}
        {demosLimitExcercised && (
          <Callout
            severity="warning"
            text={`You have reached the maximum number of ${flags.demonstration_limits.value} enablement environments.
                                Please remove one before attempting to create more.`}
          />
        )}
        <Typography variant="body">
          Create an environment with any of Okta's identity clouds. Once you are
          finished you can clean up the environment and its resources in a
          single click.
        </Typography>
        <Form
          formActions={
            <>
              {waiting && <CircularProgress />}
              <Button
                label="Create"
                type="submit"
                variant="primary"
                isDisabled={waiting}
                onClick={submitForm}
              />
            </>
          }
        >
          <Fieldset legend="Your Demo">
            {flagsmith.getTrait('connection') !== 'guest' && (
              <Fieldset>
                <RadioGroup
                  label="Purpose"
                  hint="Select how this environment will be used."
                  onChange={handleChange}
                  isDisabled={waiting || demosLimitExcercised}
                >
                  <Radio
                    id="type-opportunity"
                    label="Opportunity"
                    name="demoType"
                    value="opportunity"
                    isChecked={type === 'opportunity'}
                    onChange={handleChange}
                  />
                  <Radio
                    id="type-enablement"
                    label="Personal Enablement / Accreditation"
                    name="demoType"
                    value="enablement"
                    isChecked={type === 'enablement'}
                    onChange={handleChange}
                    isDisabled={demosLimitExcercised}
                  />
                </RadioGroup>
                {type === 'opportunity' &&
                  flags.opportunity_linking.enabled &&
                  opportunities.length > 0 && (
                    <OpportunitySelect
                      allowEdit={!waiting}
                      opportunitySelected={handleChange}
                      error={opportunityError}
                    />
                  )}
              </Fieldset>
            )}
            <TextField
              name="label"
              label="Label"
              hint="Enter a name that will help you find this environment again."
              errorMessage={labelError}
              value={label}
              onChange={handleChange}
              isDisabled={waiting}
            />
          </Fieldset>

          <Fieldset legend="Identity Cloud">
            <RadioGroup
              label="Identity Provider Platform"
              hint="Select which identity cloud you wish to deploy."
              isDisabled={waiting}
            >
              <Radio
                id="idp-cic"
                label="Customer Identity Cloud"
                name="IDPtype"
                value="customer-identity"
                isChecked={idpType === 'customer-identity'}
                onBlur={validateForm}
                onChange={handleChange}
              />
              {flags.create_workforce_org.enabled && (
                <Radio
                  id="idp-workforce"
                  label="Workforce Identity Cloud"
                  name="IDPtype"
                  value="workforce-identity"
                  isChecked={idpType === 'workforce-identity'}
                  onChange={handleChange}
                  onBlur={validateForm}
                />
              )}
              {flags.create_cis_org.enabled && (
                <Radio
                  id="idp-oci"
                  label="Okta Customer Identity Solution"
                  name="IDPtype"
                  value="okta-customer-identity"
                  isChecked={idpType === 'okta-customer-identity'}
                  onBlur={validateForm}
                  onChange={handleChange}
                />
              )}
            </RadioGroup>

            {idpType === 'workforce-identity' &&
              flags.idp_variants.enabled &&
              (flags.idp_variant_wic_preview.enabled ||
                flags.idp_variant_wic_gcp.enabled) && (
                <RadioGroup
                  label="Variant"
                  hint="Select the implementation of Okta Workforce you require."
                  isDisabled={waiting}
                >
                  <Radio
                    id="idpVariant-production"
                    label="Production"
                    name="idpVariant"
                    value="production"
                    isChecked={idpVariant === 'production'}
                    onBlur={validateForm}
                    onChange={handleChange}
                  />
                  {flags.idp_variant_wic_preview.enabled && (
                    <Radio
                      id="idpVariant-preview"
                      label="Preview"
                      name="idpVariant"
                      value="preview"
                      isChecked={idpVariant === 'preview'}
                      onChange={handleChange}
                      onBlur={validateForm}
                    />
                  )}
                  {flags.idp_variant_wic_gcp.enabled && (
                    <Radio
                      id="idpVariant-gcp"
                      label="Okta on Google Cloud Platform"
                      name="idpVariant"
                      value="gcp"
                      isChecked={idpVariant === 'gcp'}
                      onBlur={validateForm}
                      onChange={handleChange}
                    />
                  )}
                </RadioGroup>
              )}
          </Fieldset>

          <TextField
            id="name"
            icon={{
              name: 'refresh',
              link: true,
              onClick: () => {
                setName(generateDemoName());
              },
            }}
            label="Identity Provider Name"
            hint="This name will be visible in the tenant url as well as some applications."
            value={name}
            onChange={handleChange}
            errorMessage={nameError}
            onBlur={validateForm}
            style={{ textTransform: 'lowercase' }}
            isDisabled={waiting}
            startAdornment={idpType !== 'customer-identity' ? 'demo-' : ''}
            endAdornment={
              <Button
                variant="floating"
                isDisabled={waiting}
                startIcon={<SyncIcon />}
                onClick={() => {
                  setName(generateDemoName());
                }}
              />
            }
            isOptional={false}
          />
        </Form>
      </Container>
    </Surface>
  );
};
export default CreateApplication;
