import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorMessage from '../components/ErrorMessage';
import { useNavigate } from 'react-router';
import NavigationButton from '../components/NavigationButton';
import ComponentSettings from '../components/ComponentSettings';
import { createApplication } from '../services/DemoAPI';
import Dependencies from '../components/Dependencies';
import { DemonstrationDiscoveryOptions } from '../components/DemonstrationDiscoveryConstants';
import {
  Form,
  Callout,
  Fieldset,
  Select,
  Surface,
  TextField,
  Button,
} from '@okta/odyssey-react-mui';
import Container from '../components/ui/Container/Container';
import Header from '../components/ui/Header/Header';
import './CreateApplication.css';
const CreateApplication = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState();
  const [baseAddress, setBaseAddress] = useState();
  const [baseAddressError, setBaseAddressError] = useState();
  const [description, setDescription] = useState();
  const [multiTenancy, setMultiTenancy] = useState('subdomain');
  const [dependencies, setDependencies] = useState();
  const [requestHook, setRequestHook] = useState();
  const [requestHookError, setRequestHookError] = useState();
  const [createHook, setCreateHook] = useState();
  const [createHookError, setCreateHookError] = useState();
  const [updateHook, setUpdateHook] = useState();
  const [updateHookError, setUpdateHookError] = useState();
  const [destroyHook, setDestroyHook] = useState();
  const [destroyHookError, setDestroyHookError] = useState();
  const [settings, setSettings] = useState({});
  const [waiting, setWaiting] = useState(false);

  const [error, setError] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  async function createApp(event) {
    event.preventDefault();
    if (isAuthenticated) {
      if (validateForm()) {
        setWaiting(true);
        createApplication(
          await getAccessTokenSilently(),
          name,
          baseAddress,
          multiTenancy,
          description,
          {
            request: requestHook,
            create: createHook,
            update: updateHook,
            destroy: destroyHook,
          },
          settings,
          dependencies
        )
          .then((response) => {
            navigate('/app/' + response.data.application_id, { replace: true });
          })
          .catch((error) => {
            setError(error);
            setWaiting(false);
          });
      }
    }
  }

  function validateForm() {
    const validURlregex =
      '((https?)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    var result = true;
    if (name === '') {
      setNameError('Please enter a name for your application.');
      result = false;
    } else {
      setNameError();
    }
    if (!baseAddress || !baseAddress.match(validURlregex)) {
      setBaseAddressError('Please enter the full URL of your application.');
      result = false;
    } else {
      setBaseAddressError();
    }
    if (requestHook && !requestHook.match(validURlregex)) {
      setRequestHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setRequestHookError();
    }
    if (createHook && !createHook.match(validURlregex)) {
      setCreateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setCreateHookError();
    }
    if (updateHook && !updateHook.match(validURlregex)) {
      setUpdateHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setUpdateHookError();
    }
    if (destroyHook && !destroyHook.match(validURlregex)) {
      setDestroyHookError('Please enter the full URL of your hook.');
      result = false;
    } else {
      setDestroyHookError();
    }
    return result;
  }

  function handleChange(event) {
    switch (event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'baseAddress':
        setBaseAddress(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'requestHook':
        setRequestHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'createHook':
        setCreateHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'updateHook':
        setUpdateHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      case 'destroyHook':
        setDestroyHook(
          event.target.value === '' ? undefined : event.target.value
        );
        break;
      default:
        break;
    }
  }

  function handleMultiTenancyChange(event, { value }) {
    setMultiTenancy(value);
  }

  return (
    <Surface className="appComponent">
      <NavigationButton destination="/" />
      <Header className="contentHeader">Create New Application</Header>
      {error ? <ErrorMessage error={error} /> : null}
      <Container>
        <Form
          className="fieldsetContainer"
          formActions={
            <>
              <Button
                className="branded"
                type="submit"
                onClick={createApp}
                label="Create"
              ></Button>
            </>
          }
        >
          <React.Fragment key=".0">
            <Fieldset>
              <TextField
                id="name"
                label="Name"
                value={name}
                onChange={handleChange}
                errorMessage={nameError}
                onBlur={validateForm}
                maxLength="30"
                isRequired={true}
              />
              <TextField
                id="description"
                value={description}
                onChange={handleChange}
                isMultiline
                label="Description"
                control="textarea"
                rows="3"
                maxLength="1024"
                placeholder="A custom demo."
                isRequired={true}
              />

              <Header as="h3">Application Configuration</Header>
              <TextField
                id="baseAddress"
                label="Base Address"
                value={baseAddress}
                onChange={handleChange}
                placeholder="https://example.com"
                errorMessage={baseAddressError}
                onBlur={validateForm}
                isRequired={true}
              />
              <Select
                label="Demonstration discovery method"
                id="multiTenancy"
                name="multiTenancy"
                onChange={handleMultiTenancyChange}
                options={DemonstrationDiscoveryOptions}
                value={multiTenancy}
                selection
                isRequired={true}
              />

              <Dependencies
                dependencies={dependencies}
                onChange={setDependencies}
                allowEdit={true}
              />

              <Header as="h3">Settings</Header>
              <ComponentSettings
                settings={settings}
                allowEdit={true}
                setSettings={setSettings}
              />

              <Header as="h3">Lifecycle Hooks</Header>
              <TextField
                id="requestHook"
                value={requestHook}
                onChange={handleChange}
                label="Requested Event"
                placeholder="https://example.com/requested"
                errorMessage={requestHookError}
                onBlur={validateForm}
              />
              <TextField
                id="createHook"
                value={createHook}
                onChange={handleChange}
                label="Created Event"
                placeholder="https://example.com/created"
                errorMessage={createHookError}
                onBlur={validateForm}
              />
              <TextField
                id="updateHook"
                value={updateHook}
                onChange={handleChange}
                label="Updated Event"
                placeholder="https://example.com/updated"
                errorMessage={updateHookError}
                onBlur={validateForm}
              />
              <TextField
                id="destroyHook"
                value={destroyHook}
                onChange={handleChange}
                label="Deleted Event"
                placeholder="https://example.com/deleted"
                errorMessage={destroyHookError}
                onBlur={validateForm}
              />
            </Fieldset>
          </React.Fragment>
        </Form>
      </Container>
    </Surface>
  );
};
export default CreateApplication;
