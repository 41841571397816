import { useState } from 'react';
import Container from '../ui/Container/Container';
import Header from '../ui/Header/Header';
import Divider from '../ui/Divider/Divider';
import { Button } from '@okta/odyssey-react-mui';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
const Productivity = ({ step, func_complete }) => {
  const [waiting, setWaiting] = useState(false);

  async function microsoft() {
    setWaiting(true);
    var microsoftStep = step;
    microsoftStep.id = step.id.microsoft;
    func_complete(step);
  }

  async function google() {
    setWaiting(true);
    var googleStep = step;
    googleStep.id = step.id.google;
    func_complete(step);
  }

  async function skip() {
    setWaiting(true);
    func_complete();
  }

  return (
    <Container>
      <Container>
        <Header>Productivity Apps</Header>
        <Container>
          <p>
            Allowing seamless interconnectivity with services that provide
            documents, calendars, and mail for your organisation is paramount.
            With Okta's Integration Network it has never been easier to connect
            these services and underpin them with strong Identity and Access
            Management. Choose your preferred productivity suite to include it
            from the Okta Integration Network and see how Okta's rich
            integrations will help you manage your workforce.
          </p>
        </Container>
        <Divider hidden />
        <Container>
          <Button
            startIcon={<GoogleIcon />}
            label="Google Workspace"
            isDisabled={waiting}
            className=""
            onClick={google}
          />
          <Button
            startIcon={<MicrosoftIcon />}
            label="Office365"
            isDisabled={waiting}
            className=""
            onClick={microsoft}
          />
          <Button
            isDisabled={waiting}
            className=""
            onClick={skip}
            label="None"
          ></Button>
        </Container>
      </Container>
    </Container>
  );
};
export default Productivity;
